import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';

export interface IListagemRmes {
  idfun: string;
  nome: string;
  presenca: number;
  falta: number;
  atestado: number;
  domingo: number;
  horaextra: number;
  pistola: number;
  bomba: number;
  cafe: number;
  almoco: number;
  janta: number;
  
}

export interface IDetalheRmes {
  fazenda: string;
  datalan: Date;
  ponto: string;
  horaextra: number;
  pistola: number;
  bomba: number;
  historico: string;
  cafe: number;
  almoco: number;
  janta: number;
}

type TotalCount = {
  data: IListagemRmes[];
  totalCount: number;
}

type TotalCountDia = {
  data: IDetalheRmes[];
  totalCount: number;
}


const getAll = async (page = 1, filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/pontosrmes?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&periodo=${filter}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};


const getById = async (page = 1, id: string, periodo = ''): Promise<TotalCountDia | Error> => {
  try {
    const urlRelativa = `/pontosrmes/${id}?page=${page}&limit=31&periodo=${periodo}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const RmesService = {
  getAll,
  getById,
};