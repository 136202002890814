// import { useEffect, useState } from 'react';
// import { useField } from '@unform/core';
// import { TextFieldProps } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs, { Dayjs } from 'dayjs';      

// type TVTextFieldProps = TextFieldProps & {  
//   name: string;
//   disabled?: boolean;
// }

// export const VTextFieldData: React.FC<TVTextFieldProps> = ({ name, ...rest }) => {
//   const { fieldName, registerField, defaultValue,  error, clearError } = useField(name);

//   const [value, setValue] = useState<Dayjs | null>(dayjs());

//   useEffect(() => {
//     registerField({
//       name: fieldName,
//       getValue: () => value,
//       setValue: (_, newValue) => setValue(newValue),
//     });
      
//   }, [registerField, fieldName, value]);
  
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DatePicker
//         format='DD/MM/YYYY'
//         defaultValue={defaultValue}
//         value={dayjs(value)}
//         disabled={rest.disabled}
//         onChange={(newValue) => setValue(newValue)}
//         onError={() => clearError()}
//         slotProps={{
//           textField: {  
//             ...rest, 
//             error:!!error,
//             helperText:error,
//             onKeyDownCapture : (e) => { error && clearError(); rest.onKeyDownCapture?.(e); }}
//         }}
//       />
//     </LocalizationProvider> 
//   );
// };


import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';      
import { size } from 'lodash';

type TVTextFieldProps = TextFieldProps & {  
  name: string;
  disabled?: boolean;
  dataSelecionada?: (novaData: Dayjs | null) => void; // Callback for selected date
}

export const VTextFieldData: React.FC<TVTextFieldProps> = ({ name, dataSelecionada, ...rest }) => {
  const { fieldName, registerField, defaultValue,  error, clearError } = useField(name);

  const [value, setValue] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    });
      
  }, [registerField, fieldName, value]);
  
  useEffect(() => {
    // Call dataSelecionada callback when value changes
    dataSelecionada?.(value);
  }, [value]);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        
        format='DD/MM/YYYY'
        defaultValue={defaultValue}
        value={dayjs(value)}
        disabled={rest.disabled}
        onChange={(newValue) => setValue(newValue)}
        onError={() => clearError()}
        slotProps={
          {
          textField: {  
            ...rest, 
            error:!!error,
            size:'small',
            helperText:error,
            onKeyDownCapture : (e) => { error && clearError(); rest.onKeyDownCapture?.(e); }}
        }}
      />
    </LocalizationProvider> 
  );
};
