
import { Environment } from '../../../environment';
import { Apicadastro } from '../axioscadastro';

export interface IListagemUsuarios {
    id: number;
    nome: string;
    usuario: string;
    status: boolean;
    urlimg: string;
}

export interface IDetalhesUsuarios {
  nome: string;
  usuario: string;
  senha: string;
  idfazenda: string;
  idlocalizacao: string;
  status: boolean;
  nivelcadastro: number;
  nivelrh: number;
  nivelfiscal: number;
  nivelestoque: number;
  nivelfaturamento: number;
  nivelfinanceiro: number;
}

export interface IUpdateUsuarios {
  idfazenda: string;
  idlocalizacao: string;
  status: boolean;
  nivelcadastro: number;
  nivelrh: number;
  nivelfiscal: number;
  nivelestoque: number;
  nivelfaturamento: number;
  nivelfinanceiro: number;
}

export interface IGetUsuarios {
  nome: string;
  usuario: string;
  senha: string;
  idfazenda: string;
  idlocalizacao: string;
  status: boolean;
  urlimg: string;
}

interface IUpdateSenha {
  nome: string;
  usuario: string;
  senhas: string;
  novasenha: string;
  confirmarsenha: string;
}

export interface ISenha {
  senha: string;
} 


export interface IPermissao {
  id: string;
  idusuario: number;
	metodo: string;
	url: string;
	descricao: string;
	permissao: boolean;
} 

type TComTotalCount = {
    data: IListagemUsuarios[];
    totalCount: number;
}

type TComTotalCountPermissao = {
  data: IPermissao[];
  totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/usuarios?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAllPermissao = async (id: string, page = 1, modulo: string, filter = ''): Promise<TComTotalCountPermissao | Error> => {
  try {
    const { data, headers } = await Apicadastro.get(`/permissao/${id}?page=${page}&modulo=${modulo}&filter=${filter}&limit=${10}`);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || 9),
      };
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalhesUsuarios): Promise<void | Error> => {
  try {
 
    const { data } = await Apicadastro.post<IDetalhesUsuarios>('/usuarios', dados);

    if (data) {
      return;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateByIdPermissao = async (id: string, pemissao: boolean): Promise<void | Error> => {
  try {

    const dados = {id: id, permissao: pemissao};

    await Apicadastro.put(`/permissao/${id}`,  dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const updateByIdImg = async (id: string, image: File | undefined, dados: ISenha): Promise<void | Error> => {
  try {

    const { data } = await Apicadastro.post<IDetalhesUsuarios>('/usuariosupdateimg', {id: id, ...dados});

    if (data) {
      const formData = new FormData();
      formData.append('file', image || ''); 
      await Apicadastro.put(`/usuariosupdateimg/${id}`, formData);
      return;
    }
    return new Error('Erro ao Atualizar Foto.');

  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao Atualizar Foto.');
  }
};

const getById = async (id: string): Promise<IGetUsuarios | Error> => {
  try {
    const { data } = await Apicadastro.get(`/usuariosperfil/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const updateById = async (id: string, dados: IUpdateUsuarios): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/usuarios/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const updatesenha = async (id: string, dados: IUpdateSenha): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/usuariossenha/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

export const UsuarioService = {
  getAll,
  create,
  getAllPermissao,
  updateByIdPermissao,
  updateByIdImg,
  getById,
  updateById,
  updatesenha,
};