import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Alimentacao, Atestado, Bomba, Dashboard, Domingo, HoraExtra, ListagemRDiario, ListagemRMes, Pistola } from '../pages/rh';
import { DetalheDePonto } from '../pages/rh/ponto/ponto';
import { DashboardEstoque, 
  DetalheAbastecimento, 
  DetalheDaOS, 
  DetalheDaSaida, 
  DetalheDaTransferencia, 
  ListagemDeOS, 
  ListagemDeRecebimentos, 
  ListagemDeSaida, 
  ListagemDeTransferencias, 
  RelatoriosLocalizacao,
  RelatoriosVencimentos,
  Relatorioabastecimento,
  Saidacombustiveis
} from '../pages/estoque';
import { useDrawerContext } from '../shared/contexts';



export const AppRoutes = () => {
  const {toggleDrawerOpen, setDrawerOptions, setDrawerItemOptions} = useDrawerContext();

  useEffect(()=>{
    setDrawerOptions([
      {
       label: 'Estoque',
       icon: 'home',
       path: '/estoque',
      },
      {
        label: 'RH',
        icon: 'people',
        path: '/rh',
       },{
        label: 'Relatorios',
        icon: 'fact_check',
        path: '/relatorios',
       }
    ]);
    
    setDrawerItemOptions([
      {
       label: 'Dashboard',
       icon: 'dashboard_customize',
       path: '/dashboardestoque',
       parent: '/estoque'
      },
      {
        label: 'Abastecimento',
        icon: 'local_gas_station',
        path: '/abastecimento',
        parent: '/estoque'
       },
       {
        label: 'Recebimento',
        icon: 'inventory',
        path: '/transferenciarecebimento',
        parent: '/estoque'
       },
       {
       label: 'Transferência',
       icon: 'sync_alt',
       path: '/transferenciaestoque',
       parent: '/estoque'
      },
      {
        label: 'Saída',
        icon: 'content_paste_go',
        path: '/saidaestoque',
        parent: '/estoque'
       },{
        label: 'Ordem de Serviço',
        icon: 'construction',
        path: '/os',
        parent: '/estoque'
       },
       {
        label: 'Saida Combustíveis',
        icon: 'local_gas_station',
        path: '/combustiveis',
        parent: '/estoque'
       },
       {
        icon: 'dashboardicon',
        path: '/dashboardfazenda',
        label: 'Página Inicial',
        parent: '/rh'
      },
      {
        icon: 'badgeicon',
        path: '/pontos/detalhe/novo',
        label: 'Ponto',
        parent: '/rh'
      },
      {
        icon: 'scheduleicon',
        path: '/pontoshoraextra/novo',
        label: 'Hora Extra',
        parent: '/rh'
      },
      {
        icon: 'agriculture',
        path: '/pontosbomba/novo',
        label: 'Bomba',
        parent: '/rh'
      },
      {
        icon: 'editnoteicon',
        path: '/pontospistola/novo',
        label: 'Pistola',
        parent: '/rh'
      },
      {
        icon: 'eventicon',
        path: '/pontosdomingo/novo',
        label: 'Domingo',
        parent: '/rh'
      },
      {
        icon: 'medicationicon',
        path: '/pontosatestado/novo',
        label: 'Atestado',
        parent: '/rh'
      },
      {
        icon: 'restaurantmenuicon',
        path: '/pontosalimentacao/novo',
        label: 'Alimentação',
        parent: '/rh'
      },
      {
        icon: 'listicon',
        path: '/pontosrdiario',
        label: 'Listagens',
        parent: '/rh'
      },
       {
        label: 'Localização',
        icon: 'print',
        path: '/restoquelocalizacao',
        parent: '/relatorios'
       },{
        label: 'Vencimento',
        icon: 'print',
        path: '/restoquevencimento',
        parent: '/relatorios'
       },{
        label: 'Abastecimento',
        icon: 'print',
        path: '/relatorioabastecimento',
        parent: '/relatorios'
       },
    ]);
  },[]);



  return (
    <Routes>
    <Route path="/dashboardfazenda" element={<Dashboard />} />
    <Route path="/pontos/detalhe/novo" element={<DetalheDePonto/>}/>
    <Route path="/pontoshoraextra/:id" element={<HoraExtra />} />
    <Route path="/pontosbomba/:id" element={<Bomba />} />
    <Route path="/pontospistola/:id" element={<Pistola />} />
    <Route path="/pontosdomingo/:id" element={<Domingo />} />
    <Route path="/pontosatestado/:id" element={<Atestado />} />
    <Route path="/pontosalimentacao/:id" element={<Alimentacao />} />
    <Route path="/pontosrdiario" element={<ListagemRDiario />} />
    <Route path="/pontosrmes" element={<ListagemRMes />} />

    <Route path="/dashboardestoque" element={<DashboardEstoque/>} />
    <Route path="/abastecimento" element={<DetalheAbastecimento/>} />
    <Route path="/transferenciaestoque" element={<ListagemDeTransferencias/>} />
    <Route path="/transferenciaestoque/detalhe/:id" element={<DetalheDaTransferencia/>} />
    <Route path="/transferenciarecebimento" element={<ListagemDeRecebimentos/>} />
    <Route path="/saidaestoque" element={<ListagemDeSaida/>} />
    <Route path="/saidaestoque/detalhe/:id" element={<DetalheDaSaida/>} />
    <Route path="/restoquelocalizacao" element={<RelatoriosLocalizacao/>}/>
    <Route path="/restoquevencimento" element={<RelatoriosVencimentos/>}/>
    <Route path="/relatorioabastecimento" element={<Relatorioabastecimento/>}/>
    <Route path="/combustiveis" element={<Saidacombustiveis/>}/>
    <Route path="/os" element={<ListagemDeOS/>} />
    <Route path="/os/detalhe/:id" element={<DetalheDaOS/>}/>
  </Routes>
  );
};