import axios, { AxiosError } from 'axios';
import { Api } from '../../axios-config';

export interface IDetalhefuncionarioponto  {
  idfun: string;
  nome: string;
  urlimg: string;
}

interface ErrorResponse {
  error: {
    default: string;
  };
}

const getAll = async (filter = ''): Promise<IDetalhefuncionarioponto> => {
  try {
    const urlRelativa = `/funcionariosponto/${filter}`;
    const { data } = await Api.get(urlRelativa);
    if (data) {
      return data;
    } else {
      throw new Error('Nenhum dado retornado. Código incorreto.');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        throw  new Error(errorMessage);
      } else if (axiosError.request) {
        throw new Error('Nenhuma resposta do servidor');
      } else {
        throw new Error(axiosError.message);
      }
    } else {
      throw new Error((error as { message: string }).message || 'Erro inesperado');
    }
  }    
};

export const Funcionariosponto = {
  getAll,
}; 