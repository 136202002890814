import { v4 as uuidv4 } from 'uuid';
import { Environment } from '../../../../environment';
import { Apiestoque } from '../axiosestoque';


export interface IListagemSubGrupoProdutos {
  idsubgrupo: string;
  grupo: string;
  descricao: string;
  status: boolean;
}

export interface IDetalheSubGrupoProduto {
  idgrupo: string;
  descricao: string;
  status: boolean;
}

type TComTotalCount = {
    data: IListagemSubGrupoProdutos[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/subgrupos?page=${page}&limit=${Environment.LIMITE_DE_LINHASSMALL}&filter=${filter}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHASSMALL),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAllList = async (idgrupo = '', filter = '', id = ''): Promise<TComTotalCount | Error> => {

  try {
    if(id != ''){
      const urlRelativa = `/subgruposlist/${id}` ;

      const { data, headers } = await Apiestoque.get(urlRelativa);

      if (data) {
        return { 
          data,
          totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
        };
      }
 
      return new Error('Erro ao listar os registros.');
    }else if(idgrupo != ''){
      const urlRelativa = `/subgruposlist?limit=${Environment.LIMITE_DE_LINHAS}&idgrupo=${idgrupo}&filter=${filter}`;

      const { data, headers } = await Apiestoque.get(urlRelativa);

      if (data) {
        return { 
          data,
          totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
        };
      }
 
      return new Error('Erro ao listar os registros.');
    }else{
      return new Error('Erro ao listar os registros.');
    }
    
    //const urlRelativa: string = id === '' ? `/subgruposlist?limit=${Environment.LIMITE_DE_LINHAS}&idgrupo=${idgrupo}&filter=${filter}` : `/subgruposlist/${id}` ;
    
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheSubGrupoProduto | Error> => {
  try {
    const { data } = await Apiestoque.get(`/subgrupos/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheSubGrupoProduto): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apiestoque.post<IDetalheSubGrupoProduto>('/subgrupos',  {idsubgrupo: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheSubGrupoProduto): Promise<void | Error> => {
  try {
    await Apiestoque.put(`/subgrupos/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apiestoque.delete(`/subgrupos/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const SubGrupoProdutoService = {
  getAll,
  getAllList,
  create,
  getById,
  updateById,
  deleteById,
};