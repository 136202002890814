
import { useEffect, useState } from 'react';
import { Alert, Avatar, Box, Grid, LinearProgress, Paper, Snackbar, SnackbarCloseReason } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VForm, useVForm, IVFormErrors, VTextFieldData, AutoCompleteFuncionario,} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import { PistolaService } from '../../../shared/services/api/fazenda/pistola/PistolaService';

interface IFormData {
  idfun: string;
  datalan: Date;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfun: yup.string().required(),
  datalan: yup.date().required('O campo é obrigatório')
  .test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();

    if (!value || value > currentDate) return false; 
    if ((currentDate.getDay() === 1 && value.getTime() < currentDate.getTime() - (3*86400000)) || (currentDate.getDay() !== 1 && value.getTime() < currentDate.getTime() - (2*86400000))) return false; 
      
    return true;
  }),
});

export const Pistola: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [urlimg, setUrlimg] = useState('');
  const [openmsg, setOpenmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error' | 'info' | 'success'>('warning');

  const handleCloseMsg = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenmsg(false);
    if (tms === 'success') {
      novoLancamento();
    }
  };

  const dataFormatada = () => {
    const dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate() - 1);
    if (dataAtual.getDay() === 0) {
      dataAtual.setDate(dataAtual.getDate() - 1);
    }
    return dataAtual.toISOString().split('T')[0];
  };
  
  useEffect(() => {
    if (id === 'novo') {
      formRef.current?.setData({
        idfun: '',
        datalan: dataFormatada(),
      });
      setUrlimg('');
    }
  }, [id]); 

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(Environment.URL_IMGFUNCIONARIO+urlimg);
    }else{
      setUrlimg('/logo.png');
    }
  };

  const novoLancamento = () =>{
    formRef.current?.setData({
      idfun: '',
      datalan: dataFormatada(),
    });
    setUrlimg('');
  }

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          PistolaService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                setTms('error');
                setMsg(result.message);
                setOpenmsg(true); 
              }else{
                setTms('success');
                setMsg('Lançamento Realizado com sucesso!');
                setOpenmsg(true);
              }
            });
        } 
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Pistola' : ''}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoSalvar
          mostrarBotaoVoltar={false}
          mostrarBotaoSalvarEFechar={false}
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={false}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvar={save} 
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave} placeholder=""onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openmsg} autoHideDuration={900} onClose={handleCloseMsg}>
          <Alert
            onClose={handleCloseMsg}
            severity={tms}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5} >
                <Box width="100%" marginRight={5} >
                  <Avatar
                    variant='rounded'
                    alt='F'
                    src={urlimg}
                    sx={{ width: '100%', height: 200 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={6} xl={6}>
                <Grid container item direction="row" spacing={2}> 
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteFuncionario 
                  isExternalLoading={id === 'novo' ? isLoading : true}
                  urlfoto={handlefoto}
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={5} lg={4} xl={4}>          
                <VTextFieldData
                  fullWidth
                  name='datalan'
                  disabled={isLoading}
                  label='Data'
                />
              </Grid>
              </Grid>
          </Grid>
          </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};