

import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon} from '@mui/material';
import { IVFormErrors, VForm, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { TerceirosService } from '../../../shared/services/api/cadastros/TerceirosService';
// import { TerceirosService } from '../../../shared/services/api/cadastros/TerceirosService';


interface IFormData {
  idfazenda: string;
  idprodutor: number;
  percentual: number;
  caepf: string;
  tipocontraparte: number;
  status: boolean;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
  idFazenda: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfazenda: yup.string().required(),
  idprodutor: yup.number().required().moreThan(0),
  percentual: yup.number().required().moreThan(0),
  caepf: yup.string().required(),
  tipocontraparte: yup.number().required().moreThan(0),
  status: yup.boolean().optional().default(true),
});

export const DialogSolicitacaoOS: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const {formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = (dados: IFormData) => {
    dados.idfazenda = props.idFazenda;
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (props.id === 'novo') {
          TerceirosService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              }else {
                onClose(valueProp);
              }
            });
        } else {
          TerceirosService
            .updateById(props.id, dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                onClose(valueProp);
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  useEffect(() => {
    if(props.id !== 'novo'){
      if(open == true){
        setIsLoading(true);
        TerceirosService.getById(props.id)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              formRef.current?.setData(result);
            }
          });
      }
    }else{
      formRef.current?.setData({
        idfazenda: props.idFazenda,
        idprodutor: '',
        percentual: '',
        caepf: '',
        tipocontraparte: '',
        status: true,
      });
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleDelete = () => {
    if (confirm('Realmente deseja apagar?')) {
      TerceirosService.deleteById(props.id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            onClose(valueProp);
          }
        });
    }
  };

  return (
    <Dialog
      open={open}
      {...other}
    >
      <DialogTitle>Solicitação</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef} onSubmit={handleSave} placeholder=''onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h1>Função em construção</h1>
                  </Grid>               
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        {/* <Button sx={{ marginRight:'20%', minWidth:'25%' }}  color='error' startIcon={<Icon>delete</Icon>}  variant="contained"  onClick={handleDelete}>Excluir</Button> */}
        <Button sx={{ minWidth:'25%'  }} variant='outlined' color='error' onClick={handleCancel}>Voltar</Button>
        {/* <Button sx={{ minWidth:'25%'  }} variant='outlined' autoFocus onClick={save}>Confirmar</Button> */}
      </DialogActions>
    </Dialog>
    
  );
}; 