import { Api } from '../../axios-config';

export interface DataDashboard {
  colaboradores: number
  presenca: number;
  outros: number;

  presencagrosca: number;
  faltagrosca: number; 
  atestadogrosca: number;
  
  presencas:[];
  falta:[];
  atestado:[];

  cafe:[]; 
  almoco:[];
  janta:[];
    
  horaextra:[]; 
  bomba:[];
  pistola:[];
}

const getByDataDashboard = async (filter = ''): Promise<DataDashboard | Error> => {
  try {
    const { data } = await Api.get(`/dashboardfazenda?periodo=${filter}`);
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const DataDashboardService = {
  getByDataDashboard,
};