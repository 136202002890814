import axios from 'axios';

import { responseInterceptor, errorInterceptor } from './interceptors';


const tokenFromLocalStorage = localStorage.getItem('APP_ACCESS_TOKEN');
const authorizationHeader = tokenFromLocalStorage ? `Bearer ${JSON.parse(tokenFromLocalStorage)}` : '';


const Api = axios.create({
  baseURL: window.env.REACT_APP_URL_BASE,
  headers: {
    Authorization: authorizationHeader
  }
});

Api.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error),
);

export { Api };