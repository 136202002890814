import { Alert, Box, DialogContent, DialogTitle, Grid, LinearProgress, Paper, Snackbar, SnackbarCloseReason, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { AutoCompleteGrupoDeProdutos01, AutoCompleteSubgrupoProdutos01 } from '../../../shared/forms';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import RLocalizacao from './RLocalizacao';
import { RelatorioEstoqueService } from '../../../shared/services/api/estoquefazenda/Relatorios/RelatoriosService';
import { jwtDecode } from 'jwt-decode';
import { LocalizacaoService } from '../../../shared/services/api/estoquefazenda/localizacao/LocalizacaoService';

interface JwtPayload {
  faz: string;
  loc: string;
}

export const RelatoriosLocalizacao: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [idGrupo, setIdGrupo] = useState('');
  const [idLocalizacao, setIdLocalizacao] = useState('');
  const [idSubGrupo, SetIdSubGrupo] = useState('');
  const [grupo, setGrupo] = useState('');
  const [localizacao, setLocalizacao] = useState('');
  const [subGrupo, SetSubGrupo] = useState('');
  const [openmsg, setOpenmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error'  | 'info'  | 'success'>('warning');  
          
  const handleCloseMsg = (
    event?: React.SyntheticEvent | Event, 
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenmsg(false);
  };  

  useEffect(() => {
    const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
    if (accessToken) {
      const decoded = jwtDecode<JwtPayload>(accessToken);
      setIdLocalizacao(decoded.loc);
      LocalizacaoService.getAllList( '', decoded.faz)
        .then((result) => {
          if (result instanceof Error) {
          } else {
            result.data.map(localizacao => ({ label: localizacao.descricao })).forEach(opcao => {
              setLocalizacao(opcao.label);
            });
          }
        });
    }
  }, []);

  const handleuuidgrupo = (uuid: string | undefined) => {
    if (uuid != null) {
      setIdGrupo(uuid);
    }
  };

  const handleuuidgrupotext = (descricao: string | undefined) => {
    if (descricao != null) {
      setGrupo(descricao);
    }
  };

  const handleuuidsubgrupo = (uuid: string | undefined) => {
    if (uuid != null) {
      SetIdSubGrupo(uuid);
    }
  };

  const handleuuidsubgrupotext = (descricao: string | undefined) => {
    if (descricao != null) {
      SetSubGrupo(descricao);
    }
  };
  const handleVisualizarLocalizacao = () => {
    if (idLocalizacao === '') {
      setTms('info');
      setMsg('Informe uma Localização');
      setOpenmsg(true);
    } else {
      setIsLoading(true);
      RelatorioEstoqueService
        .Localizacao(idLocalizacao, 'localizacao', '', '')
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true);
          } else {
            RLocalizacao(result, localizacao, '', '');
          }
        });
    }
  };

  const handleVisualizargrupo = () => {
    if (idGrupo === '') {
      setTms('info');
      setMsg('Informe uma Grupo');
      setOpenmsg(true);
    } else {
      setIsLoading(true);
      RelatorioEstoqueService
        .Localizacao(idLocalizacao, 'grupo', idGrupo, '')
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true);
          } else {
            RLocalizacao(result, localizacao, grupo, '');
          }
        });
    }
  };

  const handleVisualizarsubgrupo = () => {
    if (idSubGrupo === '') {
      setTms('info');
      setMsg('Informe uma SubGrupo');
      setOpenmsg(true);
    } else {
      setIsLoading(true);
      RelatorioEstoqueService
        .Localizacao(idLocalizacao, 'grupo', idGrupo, idSubGrupo)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true);
          } else {
            RLocalizacao(result, localizacao, grupo, subGrupo);
          }
        });
    }
  };

  return (
    <Box alignItems="center" width='auto' alignContent='center' height="auto" display="flex" flexDirection="column"  >
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openmsg} autoHideDuration={900} onClose={handleCloseMsg}>
        <Alert
          onClose={handleCloseMsg}
          severity={tms}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Box gap={1} padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' alignContent='center'></Box>
      <Box width='50%' alignItems="center" component={Paper} height="50%" margin={1}
        alignContent='center'>
        <DialogTitle>Relatório de Localização</DialogTitle>
        <DialogContent dividers>
          {isLoading && (
            <Grid item>
              <LinearProgress variant='indeterminate' />
            </Grid>
          )}
          <Grid container padding={1} item direction="row" gap={1}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={11}>
                <TextField
                  fullWidth
                  size='small'
                  value={localizacao}
                  label='Localização'
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 35 }} color='primary' onClick={() => { handleVisualizarLocalizacao(); }} />
              </Grid>
            </Grid>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={11}>
                <AutoCompleteGrupoDeProdutos01
                  uuidgrupo={handleuuidgrupo}
                  grupo={handleuuidgrupotext}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 35 }} color='primary' onClick={() => { handleVisualizargrupo(); }} />
              </Grid>
            </Grid>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={11}>
                <AutoCompleteSubgrupoProdutos01
                  idgrupo={idGrupo}
                  uuidsubgrupo={handleuuidsubgrupo}
                  subgrupo={handleuuidsubgrupotext}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 35 }} color='primary' onClick={() => { handleVisualizarsubgrupo(); }} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Box>
  );
};