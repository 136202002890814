
import axios, { AxiosError } from 'axios';
import { Api } from '../../axios-config';
import { v4 as uuidv4 } from 'uuid';

export interface IDetalhePonto {
  idfun: string;
  presenca: number;
}

interface ErrorResponse {
  error: {
    default: string;
  };
}


const create = async (dados: IDetalhePonto): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const { data } = await Api.post<IDetalhePonto>('/pontoscartao',  {idponto: guuid, ...dados});
    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

export const PontoService = {
  create, 
};