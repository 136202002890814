import { Box, Button, FormControl, Icon, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br'); 

interface IFerramentasDaListagemProps {
  textoDaBusca?: string;
  mostrarInputBusca?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  aoClicarEmNovo?: () => void;
}

export const FerramentasDaListagemPeriodo: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = `${dayjs().format('MMMM')}/${dayjs().year()}`,
  aoMudarTextoDeBusca,
  mostrarInputBusca = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
}) => {
  const theme = useTheme();
  
  useEffect(() => {
    const [mesSeparado, anoSeparado] = textoDaBusca.split('/');
    setMes(mesSeparado);
    setAno(anoSeparado);
  }, [textoDaBusca]);

  const [mes, setMes] = React.useState('');
  const handleChangeMes = (event: SelectChangeEvent) => {
    setMes(event.target.value as string);
  };

  const [ano, setAno] = React.useState('');
  const handleChangeAno = (event: SelectChangeEvent) => {
    setAno(event.target.value as string);
  };

  const mesSelect = [
    { mes:'janeiro', nomeMes: 'Janeiro'},
    { mes:'fevereiro', nomeMes: 'Fevereiro'},
    { mes:'marco', nomeMes: 'Março'},
    { mes:'abril', nomeMes: 'Abril'},
    { mes:'maio', nomeMes: 'Maio'},
    { mes:'junho', nomeMes: 'Junho'},
    { mes:'julho', nomeMes: 'Julho'},
    { mes:'agosto', nomeMes: 'Agosto'},
    { mes:'setembro', nomeMes: 'Setembro'},
    { mes:'outubro', nomeMes: 'Outubro'},
    { mes:'novembro', nomeMes: 'Novembro'},
    { mes:'dezembro', nomeMes: 'Dezembro'},
  ]

  const anoSelect = ['2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034' ];

  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
      sx={{ height: '60px' }}
    >
      {mostrarInputBusca && (
        <>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mês</InputLabel>
              <Select
                size='small'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mes}
                label="Mês"
                onChange={(event) => {
                  handleChangeMes(event);
                  aoMudarTextoDeBusca?.(`${event.target.value}/${ano}`);
                }}
              >
                {mesSelect.map((item) => ( 
                  <MenuItem value={item.mes}>
                    {item.nomeMes}
                  </MenuItem>
                ))}
                
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Ano</InputLabel>
              <Select
                size='small'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={ano}
                label="Ano"
                onChange={(event) => {
                  handleChangeAno(event);
                  aoMudarTextoDeBusca?.(`${mes}/${event.target.value}`);
                }}
              >
                {anoSelect.map((item) => ( 
                  <MenuItem value={item}>
                    {item}
                  </MenuItem>
                ))}
                
              </Select>
            </FormControl>
          </Box>
        </>
      )}

      <Box flex={1} display="flex" justifyContent="end">
        {mostrarBotaoNovo && (
          <Button
            color='primary'
            disableElevation
            variant='contained'
            onClick={aoClicarEmNovo}
            endIcon={<Icon>add</Icon>}
          >{textoBotaoNovo}</Button>
        )}
      </Box>
    </Box>
  );
};