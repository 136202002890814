import axios, { AxiosError } from 'axios';
import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';

export interface IListagemRdiario {
  nome: string;
  ponto: string;
  horaextra: number;
  pistola: number;
  bomba: number;
  historico: string;
  cafe: number;
  almoco: number;
  janta: number;
}

export interface IListagemRdiarioTotais {
  presenca: number;
  falta: number;
  atestado: number;
  horaextra: number;
  pistola: number;
  bomba: number;
  cafe: number;
  almoco: number;
  janta: number;
}

type TotalCount = {
  data: IListagemRdiario[];
  totalCount: number;
}

interface ErrorResponse {
  error: {
    default: string;
  };
}

const getAll = async (page = 1, filter = ''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/pontosrdiario?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&data=${filter}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

const getById = async (filter = ''): Promise<IListagemRdiarioTotais | Error> => {
  try {
    const { data } = await Api.get(`/pontosrdiariototais?data=${filter}`);
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro de Totais.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

export const RdiarioService = {
  getAll,
  getById,
};