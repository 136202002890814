import { useState, useEffect } from 'react';
import { Alert, Box, Grid, LinearProgress, Paper, Snackbar, SnackbarCloseReason} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Abateciemento, IAbastecimentoSaida } from '../../../shared/services/api/estoquefazenda/abastecimento/abastecimentoservice';
import { VForm, useVForm,  VTextFieldData, IVFormErrors, AutoCompleteProduto2, VFieldMoney} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { SaidaService } from '../../../shared/services/api/estoquefazenda/saida/SaidaService';
import { AutoCompleteParceiro } from '../../../shared/forms/AutoCompleteParceiro';

const formValidationSchema: yup.ObjectSchema<IAbastecimentoSaida> = yup.object().shape({
  idprod: yup.string().required(),
  idparceiro: yup.string().required(),
  dataoperacao: yup.date().required(),
  quantidade: yup.number().required('Quantidade inválida!')
    .test('is-valid-quantidade', 'Quantidade maior que o tanque / Quantidade menor que o Estoque', function (value) {
      const { quantidadeestoque, litros } = this.parent;
      if (quantidadeestoque != null) {
        return value > 0 && value <= quantidadeestoque;
      }
      return false;
    }),
  preco: yup.number().required().moreThan(0),
  total: yup.number().required().moreThan(0),
  quantidadeestoque: yup.number().required(),
});
 
export const Saidacombustiveis: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { formRef, save } = useVForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [presosaidaa, setprecosaida] = useState(0);
  const [idprod, setidprod] = useState<string>();
  const [totalvalor, settotalvalor] = useState(0);
  const [quantidadeestoque, setquantidadeestoque] = useState(0);
  const [openmsg, setOpenmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error'  | 'info'  | 'success'>('warning');  

  const handleCloseMsg = (
    event?: React.SyntheticEvent | Event, 
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenmsg(false);
    if(tms === 'success'){ 
      navigate('/dashboardestoque')
    }
  };

  const dataFormatada = () => {
    const dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    if (dataAtual.getDay() === 0) {
      dataAtual.setDate(dataAtual.getDate());
    }
    return dataAtual.toISOString().split('T')[0];
  };
  const handleprecosaida = (psaida: number | undefined) => {
    if (psaida != null) {
      setprecosaida(psaida);
    }
  };

    useEffect(()=>{
      formRef.current?.setData({
        idprod: '',
        idparceiro: '4d340f89-3d9e-4ae3-a6d5-4f8766113a03',
        dataoperacao: dataFormatada(),
        quantidade: 0,
      });
    },[id]);

   useEffect(() => {
    if(idprod != null ){
        SaidaService
    .estoqueQuantidadeFaz({ idprod, vencimento: false, validade: null })
    .then((result) => {
     if (result instanceof Error) {
        setTms('error');
        setMsg('erro em quantidade');
        setOpenmsg(true); 
     } else {
      setquantidadeestoque(result.quantidade);
     }
     });
    }
   }, [idprod]);

   const handlepreco = (valor: string | undefined) => {
   const preco = presosaidaa;
   const quantidadevalor = valor;
    if (preco && quantidadevalor) {
      const quantidadeNumerica = Number(quantidadevalor.replace(',', '.'));
      if (!isNaN(quantidadeNumerica)) {
        const total = Number(preco) * quantidadeNumerica;
        const totalArredondado = Math.round(total * 10000) / 10000;  
        settotalvalor(totalArredondado);  
      } else {
        setTms('error');
        setMsg('Quantidade inválida.');
        setOpenmsg(true); 
      }
    } else {
      setTms('error');
      setMsg('campo de combustivel não selecionado corretamente.');
      setOpenmsg(true); 
    }
  };

  const handleSave = (dados: IAbastecimentoSaida) => {
    const dadosAtualizados = { ...dados, total: totalvalor, preco: presosaidaa, quantidadeestoque: quantidadeestoque,};
    formValidationSchema
      .validate(dadosAtualizados, { abortEarly: false })
      .then((result) => {
        setIsLoading(true);
        if (id === 'novo') {
          Abateciemento
            .saida(result)
            .then((result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                setTms('error');
                setMsg(result.message);
                setOpenmsg(true);          
              } else {
                setTms('success');
                setMsg('Lançamento Realizado com sucesso!');
                setOpenmsg(true);
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      })
  };
  return (
    <LayoutBaseDePagina
      titulo={'Saida Combustíveis'}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoNovo={false}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao={false}
          mostrarBotaoSalvar
          aoClicarEmSalvar={save}
          aoClicarEmVoltar={() => navigate('/dashboardestoque')}
          mostrarBotaoVoltarCarregando={isLoading}
          mostrarBotaoSalvarCarregando={isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave} placeholder="Seu Placeholder Aqui"onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openmsg}  autoHideDuration={900} onClose={handleCloseMsg}>
        <Alert
          onClose={handleCloseMsg}
          severity={tms}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {msg}
        </Alert>
      </Snackbar>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Grid container item direction="row" spacing={1} >
                <Grid item xs={12} sm={12} md={6} lg={10} xl={10}>
                    <AutoCompleteParceiro
                      isExternalLoading={true}
                    />   
                  </Grid> 
                  <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                    <VTextFieldData
                      fullWidth
                      name='dataoperacao'
                      label='Data de Saida'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={5} xl={5}>
                    <AutoCompleteProduto2
                      Label='Tipo Combustível'
                      combustivel='true'
                      precoSaida={handleprecosaida}
                      idproduto={setidprod}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                    <VFieldMoney  
                      fullWidth
                      size='small'
                      name='quantidade'
                      label='Quantidade'
                      calcular={handlepreco}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};