
import { v4 as uuidv4 } from 'uuid';
import { Apiestoque } from '../../estoque/axiosestoque';
import axios, { AxiosError } from 'axios';

export interface IDetalheAbastecimento  {
  idpatrimonio:string,
  idprod:string,
  dataoperacao: Date,
  idcultivo?: string | null;
  idpivot?: string | null;
  idatividade: string,
  horimetro: number,
  horimetronicial: number,
  quantidade: number,
  preco:number,
  total:number,
  operador: string,
  horastrabalhadas: number,
  litros: number,
  kmhr: string,
  quantidadeestoque: number,
}

export interface IAbastecimentoSaida{
  idprod: string;
  idparceiro: string;
  dataoperacao: Date; 
  quantidade: number;
  preco: number;
  total: number;
  quantidadeestoque: number,
}

interface ErrorResponse {
  error: {
    default: string;
  };
}

const create = async (dados: IDetalheAbastecimento): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const { quantidadeestoque, kmhr, litros, ...dadosIDetalheAbastecimento } = dados;
    const { data } = await Apiestoque.post<IDetalheAbastecimento>('/abastecimento',  {idabastecimento: guuid, ...dadosIDetalheAbastecimento});
    
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

const saida = async (dados: IAbastecimentoSaida): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const { quantidadeestoque, ...dadosIDetalheAbastecimento } = dados;
    const { data } = await Apiestoque.post<IAbastecimentoSaida>('/combustiveis',  {idabastecimento: guuid, ...dadosIDetalheAbastecimento});
    
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

export const Abateciemento = {
  create,
  saida,
};