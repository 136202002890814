import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';

type TVTextFieldProps = TextFieldProps & {
  name: string;
  calcular?: (novoTextoSelect: string | undefined) => void;
};

export const VTextFieldNumber2: React.FC<TVTextFieldProps> = ({ calcular, name, ...rest }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  const [value, setValue] = useState<string>(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => formatValueNumber(value),
      setValue: (_, newValue) => setValue(formatValueString(newValue)),
    });
  }, [registerField, fieldName, value]);

  const formatValueString = (value: string) => {
    return Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace('.', ',');
  };

  const formatValueNumber = (value: string) => {
    if (!value) {
      return '';
    }
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, '');
    // Ajuste para o formato sem ponto como separador de milhar
    value = value.replace(/(\d)(\d{2})$/, '$1.$2'); // Ajuste para duas casas decimais com ponto
    return value;
  };

  const formatInputValue = (inputValue: string) => {
    inputValue = inputValue.replace(/\D/g, ''); // Remove tudo o que não for número
    inputValue = inputValue.replace(/(\d)(\d{2})$/, '$1.$2'); // Ajuste para duas casas decimais com ponto
    return inputValue; // Sem a substituição do ponto por vírgula
  };

  return (
    <TextField
      {...rest}
      error={!!error}
      helperText={error}
      defaultValue={defaultValue}
      value={value || ''}
      onChange={(e) => {
        const inputValue = formatInputValue(e.target.value);
        e.target.value = inputValue;
        setValue(inputValue);
        calcular?.(e.target.value);
        rest.onChange?.(e);
      }}
      onKeyDown={(e) => {
        if (error) {
          clearError();
        }
        rest.onKeyDown?.(e);
      }}
    />
  );
};
