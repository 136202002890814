import axios, { AxiosError } from 'axios';
import { Api } from '../../axios-config';

export interface IDetalheHoraExtra {
  idfun: string;
  datalan: Date;
}

interface ErrorResponse {
  error: {
    default: string;
  };
}


const create = async (dados: IDetalheHoraExtra): Promise<void | Error> => {
  try {
    await Api.post('/pontospistola', dados);
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

export const PistolaService = {
  create,
};