import { Environment } from '../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Apicadastro } from '../axioscadastro';

export interface IListagemFazenda {
  idfazenda: string;
  descricao: string;
  endereco: string;
}

export interface IDetalheFazenda {
  descricao: string;
  status: boolean;
  caditr: number;
  endereco: string;
  numero: string;
  compl: string;
  bairro: string;
  idmunicipio: string;
  cep: string;
  tipoexploracao: number;
  ir: boolean;
}

type TotalCount = {
    data: IListagemFazenda[];
    totalCount: number;
}

const getAlllist = async (id = '', filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa: string = id === '' ?  `/fazendaslist?limit=${Environment.LIMITE_DE_CADASTRO}&filter=${filter}` : `/fazendaslist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_CADASTRO),
      };
    }
  
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAll = async (page = 1, tipo = '', filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/fazendas?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&tipo=${tipo}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheFazenda | Error> => {
  try {
    const { data } = await Apicadastro.get(`/fazendas/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheFazenda): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apicadastro.post<IDetalheFazenda>('/fazendas',  {idfazenda: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheFazenda): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/fazendas/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/fazendas/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const FazendaService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
};