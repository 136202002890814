import axios, { AxiosError } from 'axios';
import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';

export interface IListagemColaborador {
  idfun: string;
  nome: string;
  apelido: string;
  cpf: string;
  urlimg: string;
}

type TotalCount = {
    data: IListagemColaborador[];
    totalCount: number;
}

interface ErrorResponse {
  error: {
    default: string;
  };
}

const getAllList = async (filter = '', id = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa: string = id === '' ? `/funcionarioslist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/funcionarioslist/${id}`;
    const { data, headers } = await Api.get(urlRelativa);
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
     return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

export const ColaboradorService = {
  getAllList,
};