import { Apiestoque } from "../../estoque/axiosestoque";

export interface DataDashboard {
  nivel: number,
  estoque: number,
  vencer: number,
  vencidos: number,
  topdez: [],
  entrada: [],
  saida: [],
}

const GetDashboardEstoqueService = async (): Promise<DataDashboard | Error> => {
  try {
    const { data } = await Apiestoque.get('/dashboard');
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const DashboardEstoqueService = {
  GetDashboardEstoqueService,
};