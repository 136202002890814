import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from '../../shared/hooks';
import { GrupoProdutoService } from '../services/api/estoque/grupoProduto/GrupoProdutoService';

type TAutoCompleteOption = {
  idgrupo: string;
  label: string;
} 

interface IAutoCompleteGrupoProps {
  isExternalLoading?: boolean;
  uuidgrupo?: (novoTextoselect: string | undefined) => void;
  grupo?: (novoTextoselect: string) => void; 
}

export const AutoCompleteGrupoDeProdutos01: React.FC<IAutoCompleteGrupoProps> = ({ isExternalLoading = false, uuidgrupo, grupo }) => {
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>();

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      GrupoProdutoService.getAlllist(busca, selectedId)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(grupo => ({ idgrupo: grupo.idgrupo, label: grupo.descricao })));
          }
        });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idgrupo === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);


  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar' 
      noOptionsText='Sem opções'
      loadingText='Carregando...'
      fullWidth
      disablePortal
      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idgrupo); uuidgrupo?.(newValue?.idgrupo); setBusca(''); grupo?.(newValue?.label ?? ''); }}
      popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'
          label="Grupo"

        />
      )}
    />
  );
};