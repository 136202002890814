import { useEffect, useMemo, useState } from 'react';
import { Alert, Button, LinearProgress, Pagination, Paper, Snackbar, SnackbarCloseReason, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { ConfirmarRecebimento, IDetalheRecebimento, IListagemRecebimento } from '../../../shared/services/api/estoquefazenda/confirmarRecebimento/ConfirmarRecebimento';

dayjs.locale('pt-br'); 

export const ListagemDeRecebimentos: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const [rowss, setRowss] = useState<IListagemRecebimento[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [idSetected, setIdSetected] = useState('');
  const [pontoData, setPontoData] = useState<IDetalheRecebimento[]>([]);
  const [openmsg, setOpenmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error' | 'info' | 'success'>('warning');

  const handleCloseMsg = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenmsg(false);
    if (tms === 'success') {
        setAtualizar(true);
    }
  };

  const busca = useMemo(() => {
    return searchParams.get('busca') || `${dayjs().format('MMMM')}/${dayjs().year()}`;
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]); 

  const [atualizar, setAtualizar] = useState(false);
  const handleConfirmar = (idConfirmar: string)=>{
    if((idConfirmar !== null || idConfirmar !== undefined || idConfirmar !== '')){
      ConfirmarRecebimento.confirmar(idConfirmar, 3)
        .then((result) => {
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true); 
          }else{
            setAtualizar(false);
            setTms('success');
            setMsg('Confirmado com sucesso!');
            setOpenmsg(true);            
          }
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      ConfirmarRecebimento.getAll(pagina)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true); 
          } else {
            setTotalCount(result.totalCount);
            setRowss(result.data);
          }
        });
    });
  }, [busca, pagina, atualizar]);

  function createData(
    idtransferencia: string,
    dataenvio: Date,
    datalan: Date,
    origem: string,
    portador: string,
  ) {
    return {
      idtransferencia,
      dataenvio,
      datalan,
      origem,
      portador,
    };
  }

  const [getid, setGetid] = React.useState(false);

  useEffect(()=>{
    if((idSetected !== null || undefined) && getid === true){
      ConfirmarRecebimento.getById(1, idSetected)
        .then((result) => {
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true); 
          }else{
            setPontoData(result.data);
          }
        });
    }
  }, [idSetected, getid]);

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props; 
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openmsg} autoHideDuration={900} onClose={handleCloseMsg}>
          <Alert
            onClose={handleCloseMsg}
            severity={tms}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {setOpen(!open); setGetid(!open); setIdSetected(row.idtransferencia);}}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">{dayjs(row.dataenvio).format('DD/MM/YYYY')}</TableCell>
          <TableCell component="th" scope="row">{dayjs(row.datalan).format('DD/MM/YYYY')}</TableCell>
          <TableCell align="center">{row.origem}</TableCell>
          <TableCell align="center">{row.portador}</TableCell>
          <TableCell align="center">
            <Button
              size="small"
              color='primary'
              disableElevation
              variant='outlined'
              onClick={() => {handleConfirmar(row.idtransferencia);}}
            >
              CONFIRMAR
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Ítens
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Quantidade</TableCell>
                      <TableCell align="center">Conversor</TableCell>
                      <TableCell align="center">UN</TableCell>
                      <TableCell align="center">Descrição</TableCell>
                      <TableCell align="center">Referência</TableCell>
                      <TableCell align="center">Validade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pontoData.map((pontodataRow) => (
                      <TableRow key={pontodataRow.iditens}>
                        <TableCell align="center">{pontodataRow.qtdentrada}</TableCell>
                        <TableCell align="center">{pontodataRow.conversor}</TableCell>
                        <TableCell align="center">{pontodataRow.un}</TableCell>
                        <TableCell align="left">{pontodataRow.descricao}</TableCell>
                        <TableCell align="left">{pontodataRow.referencia}</TableCell>
                        <TableCell align="center" component="th" scope="row">{pontodataRow.validade ? dayjs(pontodataRow.validade).format('DD/MM/YYYY') : ''}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const rows = useMemo(() => {
    return rowss.map(row => createData(
      row.idtransferencia,
      row.dataenvio,
      row.datalan,
      row.origem,
      row.portador
    ));
  }, [rowss]);  
  
  return (
    <LayoutBaseDePagina
      titulo='Confirmar Recebimentos'
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table size='small' aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Data Envio</TableCell>
              <TableCell>Data Lançamento</TableCell>
              <TableCell align="center">Origem</TableCell>
              <TableCell align="center">Portador</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {rows.map((row) => (
              <Row key={row.idtransferencia} row={row} />
            ))}
          </TableBody>
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow >
                <TableCell colSpan={6}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};