// ChartComponent.ts

import React, { useEffect, useRef, useCallback } from 'react';
import ApexCharts from 'apexcharts';

interface ChartOptions {
  series: number[];
  chart: {
    type: string;
    width?: number;
  };
  labels: string[];
  colors: ['#00E396', '#FF4560', '#14AEE0'];
  theme?: {
    mode?: 'dark' | 'light' | undefined;
  };
  responsive?: {
    breakpoint: number;
    options: {
      chart: {
        width: number;
        height: number;
      };
      legend: {
        position: string;
      };
    };
  }[];
}

interface GraficoRoscaProps {
  series: number[];
  chart: ChartOptions['chart'];
  width?: number;
  labels: string[];
  themeMode?: 'dark' | 'light' | undefined; // Add themeMode prop
}

const GraficoRosca: React.FC<GraficoRoscaProps> = ({
  series,
  chart,
  width = 300,
  labels,
  themeMode = 'dark', // Default to 'dark' if not provided
}) => {
  const options: ChartOptions = {
    series,
    chart: {
      ...chart,
      width,
    },
    labels,
    colors: ['#00E396', '#FF4560', '#14AEE0'],
    theme: {
      mode: themeMode, // Use the provided theme mode
    },
    responsive: [
      {
        breakpoint: 100,
        options: {
          chart: {
            width: 200,
            height: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const chartRef = useRef<ApexCharts | null>(null);

  const renderChart = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new ApexCharts(document.querySelector('#chart'), options);
    chartRef.current.render();
  }, [options]);

  useEffect(() => {
    renderChart();

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [renderChart]);

  return <div id="chart" />;
};

export default GraficoRosca;
