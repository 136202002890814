import { Environment } from '../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Apicadastro } from '../axioscadastro';

export interface IListagemTerceirosFazenda {
  idterceiros: string;
  produtor: string;
  percentual: number;
  contraparte: string;
}

export interface IDetalheTerceirosFazenda {
  idfazenda: string;
  idprodutor: number;
  percentual: number;
  caepf: string;
  tipocontraparte: number;
  status: boolean;
}

type TotalCount = {
    data: IListagemTerceirosFazenda[];
    totalCount: number;
}

const getAlllist = async (id = '', filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa: string = id === '' ?  `/terceirosfazendalist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/ceilist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAll = async (page = 1, id: string): Promise<TotalCount | Error> => {

  try {
    const urlRelativa = `/terceirosfazenda?idfazenda=${id}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheTerceirosFazenda | Error> => {
  try {
    const { data } = await Apicadastro.get(`/terceirosfazenda/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheTerceirosFazenda): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apicadastro.post<IDetalheTerceirosFazenda>('/terceirosfazenda',  {idterceiros: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheTerceirosFazenda): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/terceirosfazenda/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/terceirosfazenda/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const TerceirosService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
};