import { useEffect, useState } from 'react';
import { Alert, Box, Button, Grid, Icon, IconButton, LinearProgress, Paper, Snackbar, SnackbarCloseReason, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField, VForm, useVForm, VTextFieldData, IVFormErrors, AutoCompleteLocalizacaoField } from '../../../shared/forms';
import dayjs from 'dayjs';
import { LayoutBaseDePaginaNfe } from '../../../shared/layouts';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { DialogTransferencia } from './DialogTransferencia';
import { v4 as uuidv4 } from 'uuid';
import { TransferenciaService } from '../../../shared/services/api/estoquefazenda/transferencia/TransferenciaService';
import { FerramentasDeDetalheTransferencia } from '../../../shared/components/ferramentas-de-detalhe/FerramentasDeDetalheTransferencia';
type NullableDate = Date | null | undefined;
interface IFormData {
  iddestino: string;
  datalan: Date;
  dataenvio?: NullableDate;
  datarecebimento?: NullableDate;
  portador: string;
  status: number;
}
interface IitensEntradaDialog{
  iditens: string;
  idprod: string;
  qtdsaida: number; 
  validade?: string | null;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;
  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  reqvencimento: boolean;
}
interface IItensids{
  iditens: string;
}
const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'status' | 'datarecebimento' | 'dataenvio'>> = yup.object().shape({
  iddestino: yup.string().required(),
  datalan: yup.date().required(),
  portador: yup.string().required(),
});
export const DetalheDaTransferencia: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [statuss, setStatuss] = useState(true);
  const [iditens, setIditens] = useState('');
  const [rows, setRows] = useState<IitensEntradaDialog[]>([]);
  const [rowsids, setRowsids] = useState<IItensids[]>([]);
  const [dadositens, setDadositens] = useState<IitensEntradaDialog>();
  const guuid = uuidv4();
  const [openmsg, setOpenmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error'  | 'info'  | 'success'>('warning');  
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmItem, setOpenConfirmItem] = useState(false);
  const [iditem, setIditem ] = useState('');
            
  const handleCloseMsg = (
    event?: React.SyntheticEvent | Event, 
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenmsg(false);
    if(tms === 'success'){ 
      navigate('/transferenciaestoque');
    }
  };
  
  const handleDeleteItem = (idi: string) => {
    setOpenConfirmItem(true); 
    setIditem(idi);
  };
          
  const handleConfirmDeleteItem = () => {
    const meuObjeto = { iditens: iditem };
    rowsids.push(meuObjeto);
    const indexToDelete = rows.findIndex((item) => item.iditens === iditem);
    if (indexToDelete !== -1) {
      const updatedList = [...rows];
      updatedList.splice(indexToDelete, 1);
      setRows(updatedList);
    }
    setOpenConfirmItem(false);
  };
          
  const handleCancelDeleteItem = () => {
    setOpenConfirmItem(false);
  };


  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      TransferenciaService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true);
          } else {
            formRef.current?.setData(result);
            setRows(result.itens);
            setStatuss(false);
          }
        });
    } else {
      formRef.current?.setData({
        iddestino: '',
        datalan: new Date(),
        portador: '',
      });
      setRows([]);
      setStatuss(true);
    }  
  }, [id]);
  useEffect(() => {
    const totalSum = rows.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0);
    formRef.current?.setFieldValue('total', totalSum);
  }, [rows]);
  const handleSave = (dados: IFormData) => {
    if(rows.length != 0){
      formValidationSchema.
        validate(dados, { abortEarly: false })
        .then((DadosValidados) => {
          setIsLoading(true);
          const dadosValidados = { ...DadosValidados, status: 2};
          if (id === 'novo') {
            TransferenciaService
              .create(dadosValidados, rows)
              .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                  setTms('error');
                  setMsg(result.message);
                  setOpenmsg(true);
                } else {
                  setTms('success');
                  setMsg('Lançamento Realizado com sucesso!');
                  setOpenmsg(true);
                } }); }else{
                  setIsLoading(false);
                  setTms('error');
                  setMsg('Acesso Negado');
                  setOpenmsg(true);
                }
        })
        .catch((errors: yup.ValidationError) => {
          const validationErrors: IVFormErrors = {};
          errors.inner.forEach(error => {
            if (!error.path) return;
            validationErrors[error.path] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        });
    }else{
      setTms('warning');
      setMsg('A saída deve conter no mínimo um ítem.');
      setOpenmsg(true);
    }
  };
  const handleBuscarDados = (id: string) => {
    const foundItem = rows.find((listItem) => listItem.iditens === id);
    if (foundItem) {
      setDadositens(foundItem);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  type RowsType = IitensEntradaDialog[];
  const handleItens = (dados: IitensEntradaDialog) => {
    const index = rows.findIndex((listItem) => listItem.iditens === dados.iditens);
    let updatedList: RowsType;
    if (index !== -1) {
      updatedList = [...rows];
      updatedList[index] = dados;
    } else {
      updatedList = [...rows, dados];
    }
    setRows(updatedList);    
  };
  return (
    <LayoutBaseDePaginaNfe
      barraDeFerramentas={
        <FerramentasDeDetalheTransferencia
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvar={false}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={false} 
          disableBotaoSalvar={statuss ? isLoading : true}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/transferenciaestoque')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmNovo={() => navigate('/transferenciaestoque/detalhe/novo')}
          mostrarBotaoNovoCarregando={isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave} placeholder={undefined}onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openmsg}  autoHideDuration={900} onClose={handleCloseMsg}>
          <Alert
            onClose={handleCloseMsg}
            severity={tms}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>;
        <Snackbar open={openConfirmItem} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            severity="warning"  
            variant="filled"
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            action={
              <>
                <Button color="inherit" size="small" onClick={handleConfirmDeleteItem}>
              Sim
                </Button>
                <Button color="inherit" size="small" onClick={handleCancelDeleteItem}>
              Nâo
                </Button>
              </>
            }
          >
            Tem certeza que deseja excluir este Item?
          </Alert>
        </Snackbar>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} >
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row"  spacing={0.5}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      name='datalan'
                      label='Data do Lançamento'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                    <AutoCompleteLocalizacaoField
                      name='iddestino'
                      Label='Destino'
                      disabled={statuss ? isLoading : true}
                    />   
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='portador'
                      label='Portador'
                      disabled={statuss ? isLoading : true}
                    />  
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} variant="outlined" sx={{width: 'auto' }}>
                  <Table size="small">
                    <TableHead  sx={{ backgroundColor: '#5E8A71' }}>
                      <TableRow >
                        <TableCell style={{ color: '#fff' }} align='right'  >Qtd</TableCell>
                        <TableCell style={{ color: '#fff' }} align='left'>UN</TableCell>
                        <TableCell style={{ color: '#fff' }} align='left'>Descrição</TableCell>
                        <TableCell style={{ color: '#fff' }} align='left'>Referência</TableCell>
                        <TableCell style={{ color: '#fff' }} align='center'>Validade</TableCell>
                        <TableCell style={{ maxWidth:'1px', maxHeight: '10px',  color: '#fff' }} align='center'>
                          <IconButton color='inherit'  disabled={statuss ? isLoading : true}  aria-label="add to shopping cart" size="small">
                            <AddShoppingCartIcon  onClick={() => {setIditens('novo');  setOpen(true);}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.iditens} 
                          hover
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell align='right' >{row.qtdsaida.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell align='left'>{row.un}</TableCell>
                          <TableCell align='left' >{row.descricao}</TableCell>
                          <TableCell align='left'>{row.referencia}</TableCell>
                          <TableCell align='center'>{row.validade ? dayjs(row.validade).format('DD/MM/YYYY') : ''}</TableCell>
                          <TableCell>
                            <IconButton  size="small" onClick={() => {setIditens(row.iditens); handleBuscarDados(row.iditens);  setOpen(true);}}>
                              <Icon >edit</Icon>
                            </IconButton>
                            <IconButton disabled={statuss ? isLoading : true}  size="small" onClick={() => {handleDeleteItem(row.iditens);}}>
                              <Icon>delete</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <LinearProgress variant='indeterminate' />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <DialogTransferencia 
              id={iditens} 
              value={guuid} 
              dados={dadositens}
              open={open} 
              onDados={handleItens} 
              onClose={handleClose} 
              status={statuss}
            /> 
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePaginaNfe>
  );
};