
import { useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Grid, Icon, IconButton, LinearProgress, Pagination, Paper, Snackbar, SnackbarCloseReason, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, AutoCompleteFazenda, VTextFieldData } from '../../../shared/forms';
import { FerramentasDaListagem, FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { SelectNumber } from '../../../shared/forms/Selectnumber';
import { Environment } from '../../../shared/environment';
import { DialogSolicitacaoOS } from './DialogSolicitacaoOS';
import { OsService } from '../../../shared/services/api/estoquefazenda/os/OsService';
import { DialogSaidaOS } from './DialogSaidaOS';
import SwipeableTextMobileStepper from './SwipeableTextMobileStepper';
import { PatrimoniosService } from '../../../shared/services/api/cadastros/PatrimoniosService';
import { jwtDecode } from 'jwt-decode';


type NullableDate = Date | null | undefined;

interface IFormData {
  idpatrimonio: string;
  idfazenda: string;
  operador: string; 
  mecanico: string; 
  dataabertura: Date;
  datafechamento?: NullableDate;
  defeitoreclamado: string; 
  defeitoconstatado: string;
  servicosrealizados: string;
  situacao: number;
}

interface IitensSaidaDialog{
  iditens: string;
  idprod: string;
  qtdsaida: number; 
  validade?: string | null;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;
  idlocalizacao: string;
  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  reqvencimento: boolean;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idpatrimonio: yup.string().required(),
  idfazenda: yup.string().required(),
  operador: yup.string().required(),
  mecanico: yup.string().required(),
  dataabertura: yup.date().required(),
  datafechamento: yup.date().nullable().optional().default(null),
  defeitoreclamado: yup.string().optional().default(''),
  defeitoconstatado: yup.string().optional().default(''),
  servicosrealizados: yup.string().optional().default(''),
  situacao: yup.number().required(),
});

interface IItensids{
  iditens: string;
}

interface IImage {
  imgPath: string;
}

interface JwtPayload {
  faz: string;
  loc: string;
}

export const DetalheDaOS: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState(0);
  const [numeroPatrimonio, setNumeroPatrimonio] = useState('');
  const [descricaoPatrimonio, setDescricaoPatrimonio] = useState('');
  const [modeloPatrimonio, setModeloPatrimonio] = useState('');
  const [images, setImages] = useState<IImage[]>([{ imgPath: `${Environment.URL_IMGPATRIMONIO}semimagem.jpg`}]);
  const [idpatrimonio, setIdpatrimonio] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [idlocalizacao, setIdlocalizacao] = useState('');
  const [rows, setRows] = useState<IitensSaidaDialog[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  const [open, setOpen] = useState(false);
  const [dadositens, setDadositens] = useState<IitensSaidaDialog>();
  const [iditens, setIditens] = useState('');
  const handleBuscarDados = (id: string) => {
    const foundItem = rows.find((listItem) => listItem.iditens === id);
    if (foundItem) {
      setDadositens(foundItem);
    }
  };
  const [openSaida, setOpenSaida] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmItem, setOpenConfirmItem] = useState(false);
  const [iditem, setIditem] = useState('');
  const [openmsg, setOpenmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error'  | 'info'  | 'success'>('warning');  

  const handleCloseMsg = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenmsg(false);
    if (tms === 'success') {
      navigate('/os');
    }
  };

  const handleDelete = () => {
    setOpenConfirm(true);
  };

  const handleDeleteItem = (idi: string) => {
    setOpenConfirmItem(true);
    setIditem(idi);
  };

  const handleConfirmDelete = () => {
    OsService.deleteById(id)
      .then(result => {
        if (result instanceof Error) {
          setOpenConfirm(false);
          setTms('error');
          setMsg(result.message);
          setOpenmsg(true);
        } else {
          setOpenConfirm(false);
          setTms('success');
          setMsg('Registro apagado com sucesso!');
          setOpenmsg(true);
        }
      });
  };

  const handleConfirmDeleteItem = () => {
    const meuObjeto = { iditens: iditem };
    rowsids.push(meuObjeto);
    const indexToDelete = rows.findIndex((item) => item.iditens === iditem);
    if (indexToDelete !== -1) {
      const updatedList = [...rows];
      updatedList.splice(indexToDelete, 1);
      setRows(updatedList);
    }
    setOpenConfirmItem(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirm(false);
  };

  const handleCancelDeleteItem = () => {
    setOpenConfirmItem(false);
  };
  
  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      OsService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true);
          } else {
            setDescricao(result.numero);
            setRows(result.itens);
            formRef.current?.setData(result);
            setDescricaoPatrimonio(result.nomepatrimonio);
            setModeloPatrimonio(result.modelo);
            setIdpatrimonio(result.idpatrimonio);
            setNumeroPatrimonio(result.npatrimonio);
            const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
            if (accessToken) {
              const decoded = jwtDecode<JwtPayload>(accessToken); 
              setIdlocalizacao(decoded.loc);
            }
            if(result.situacao === 3){
              setDisabled(false);
            }
            if(result.fotos === null || result.fotos.length === 0){
              setImages(imagesnovo);
            }else{
              const imageList: IImage[] = [];
              result.fotos.forEach((item) => {
                imageList.push({ imgPath: Environment.URL_IMGPATRIMONIO+item.imgPath });
              });
              setImages(imageList);
            }  
          }
        });
    } else {
      formRef.current?.setData({
        idpatrimonio: '',
        idfazenda: '',
        operador: '',
        mecanico: '',
        dataabertura: new Date(),
        datafechamento: null,
        defeitoreclamado: '',
        defeitoconstatado: '',
        servicosrealizados: '',
        situacao: 1,
        numero: 0,
      });
      setRows([]);
      setImages(imagesnovo);
      setDescricaoPatrimonio('');
      setModeloPatrimonio('');
      setIdpatrimonio('');
      setNumeroPatrimonio('');
      const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
      if (accessToken) {
        const decoded = jwtDecode<JwtPayload>(accessToken);
        formRef.current?.setFieldValue('idlocalizacao', decoded.loc);
        setIdlocalizacao(decoded.loc);
        formRef.current?.setFieldValue('idfazenda', decoded.faz);
      }
    }
  }, [id]);
  
  const handleSave = (dados: IFormData) => {
    dados.idpatrimonio = idpatrimonio;
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          OsService
            .create(dadosValidados, rows)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                setTms('error');
                setMsg(result.message);
                setOpenmsg(true);
              } else {
                if (isSaveAndClose()) {
                  navigate('/os');
                } else {
                  navigate(`/os/detalhe/${result}`);
                }
              }
            });
        } else {
          OsService
            .updateById(id, dadosValidados, rows, rowsids )
            .then((result) => {
              setIsLoading(false);
              if(dadosValidados.situacao === 3){
                setDisabled(false);
              }
              if (result instanceof Error) {
                setTms('error');
                setMsg(result.message);
                setOpenmsg(true);
              } else {
                if (isSaveAndClose()) {
                  navigate('/os');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const [rowsids, setRowsids] = useState<IItensids[]>([]);
  const handleDeleteItemSaida = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      const meuObjeto = {iditens: id};  
      rowsids.push(meuObjeto); 
      const indexToDelete = rows.findIndex((item) => item.iditens === id);
      if (indexToDelete !== -1) {
        const updatedList = [...rows];
        updatedList.splice(indexToDelete, 1);
        setRows(updatedList);
      }
    }
  };
  const [detalheterceiro, setdetalheterceiro ] = useState<string>('novo');
  const handleClose = (newValue?: string) => {
    setOpen(false);
  };
  const handleCloseSaida = (newValue?: string) => {
    setOpenSaida(false);
  };
  type RowsType = IitensSaidaDialog[];
  const handleItens = (dados: IitensSaidaDialog) => {
    const index = rows.findIndex((listItem) => listItem.iditens === dados.iditens);
    let updatedList: RowsType;
  
    if (index !== -1) {
      updatedList = [...rows];
      updatedList[index] = dados;
    } else {
      updatedList = [...rows, dados];
    }
    setRows(updatedList);
  };
  const handleFechamento = (valor: string | undefined) => {
    if(Number(valor) === 3){
      formRef.current?.setFieldValue('datafechamento', new Date());
    }else{
      formRef.current?.setFieldValue('datafechamento', null);
    }
  }; 
  const imagesnovo = [{ imgPath: `${Environment.URL_IMGPATRIMONIO}semimagem.jpg`, arquivo: undefined }];
  const handlebusca = (numeroPatrimonios: string) => {
    setIsLoading(true);
    if (numeroPatrimonios) {
      PatrimoniosService.getAllListNumero(numeroPatrimonios)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true);
          } else {
            setDescricaoPatrimonio(result.descricao);
            setModeloPatrimonio(result.modelo);
            setIdpatrimonio(result.idpatrimonio);
            if(result.fotos === null || result.fotos.length === 0){
              setImages(imagesnovo);
            }else{
              const imageList: IImage[] = [];
              result.fotos.forEach((item) => {
                imageList.push({ imgPath: Environment.URL_IMGPATRIMONIO+item.imgPath });
              });
              setImages(imageList);
            }  
          }
        });
    }
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Nova OS' : `Ordem de Serviço: ${String(descricao)}`}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Nova'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvar={false}
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={false}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/os')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete}
          aoClicarEmNovo={() => navigate('/os/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave} placeholder=''onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openmsg} autoHideDuration={900} onClose={handleCloseMsg}>
          <Alert
            onClose={handleCloseMsg}
            severity={tms}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>;
        <Snackbar open={openConfirm} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            severity="warning"
            variant="filled"
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            action={
              <>
                <Button color="inherit" size="small" onClick={handleConfirmDelete}>
                  Sim
                </Button>
                <Button color="inherit" size="small" onClick={handleCancelDelete}>
                  Nâo
                </Button>
              </>
            }
          >
            Tem certeza que deseja excluir este registro?
          </Alert>
        </Snackbar>
        <Snackbar open={openConfirmItem} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            severity="warning"
            variant="filled"
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            action={
              <>
                <Button color="inherit" size="small" onClick={handleConfirmDeleteItem}>
                  Sim
                </Button>
                <Button color="inherit" size="small" onClick={handleCancelDeleteItem}>
                  Nâo
                </Button>
              </>
            }
          >
            Tem certeza que deseja excluir este Item?
          </Alert>
        </Snackbar>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>  
                <SwipeableTextMobileStepper
                  images={images} 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
                <Grid container item direction="row" spacing={1}> 
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {id === 'novo' ?     
                      <TextField
                        fullWidth  
                        size='small'
                        value={numeroPatrimonio}
                        label='Nº Patrimônio'
                        disabled={isLoading}
                        onChange={e => setNumeroPatrimonio(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && numeroPatrimonio.trim() !== '') {
                            handlebusca(numeroPatrimonio);
                          }
                        }}
                      />
                      :   
                      <TextField
                        fullWidth
                        size='small'
                        value={numeroPatrimonio}
                        disabled={true}
                        label='Nº Patrimônio'
                      />
                    }                     
                  </Grid> 
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>     
                    <TextField
                      fullWidth
                      size='small'
                      value={descricaoPatrimonio}
                      disabled={true}
                      label='Patrimônio'
                    />   
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>     
                    <TextField
                      fullWidth
                      size='small'
                      value={modeloPatrimonio}
                      disabled={true}
                      label='Modelo'
                    />   
                  </Grid>
                  
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>          
                    <VTextFieldData
                      fullWidth
                      name='dataabertura'
                      disabled={true}
                      label='Data de Abertura'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>          
                    <VTextFieldData
                      fullWidth
                      name='datafechamento'
                      disabled={true}
                      label='Data de Fechamento'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>     
                    <AutoCompleteFazenda
                      isExternalLoading={true}
                    />     
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>          
                    <SelectNumber
                      data={[
                        { nameBD: 1, descricao: 'Execução' },
                        { nameBD: 2, descricao: 'Aguardando Peças' },
                        { nameBD: 3, descricao: 'Finalizado' },
                      ]}
                      label='Situação'
                      name='situacao'  
                      disabled={disabled ? isLoading : true}
                      valor={handleFechamento}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                    <VTextField
                      fullWidth
                      name='operador'
                      size='small'
                      disabled={disabled ? isLoading : true}
                      label='Operador'
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                    <VTextField
                      fullWidth
                      size='small'
                      name='mecanico'
                      disabled={disabled ? isLoading : true}
                      label='Mecânico'
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                    <VTextField
                      fullWidth
                      size='small'
                      name='defeitoreclamado'
                      disabled={disabled ? isLoading : true}
                      label='Defeito Reclamado'
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                    <VTextField
                      fullWidth
                      size='small'
                      name='defeitoconstatado'
                      disabled={disabled ? isLoading : true}
                      label='Defeito Constatado'
                      multiline
                      rows={3}
                    />
                  </Grid>
                  </Grid>
                  </Grid>
                  <Grid container item direction="row"> 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>          
                    <VTextField
                      fullWidth
                      size='small'
                      name='servicosrealizados'
                      disabled={disabled ? isLoading : true}
                      label='Serviços Realizados'
                      multiline
                      rows={2}
                    />
                  </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>    
      <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
        <Grid container direction="column" padding={2} spacing={2}>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>    
              <FerramentasDaListagem
                textoTitulo='Peças Solicitadas'
                mostrarTextoTitulo={true}
                textoBotaoNovo='Adicionar'
                aoClicarEmNovo={() => {setdetalheterceiro('novo'); setOpen(true);}}
              />

              <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data/Hora</TableCell>
                      <TableCell>Solicitante</TableCell>
                      <TableCell>Fazenda</TableCell>
                    </TableRow>
                  </TableHead>
  
                  {totalCount === 0 && !isLoading && (
                    <caption>{Environment.LISTAGEM_VAZIA}</caption>
                  )}
  
                  <TableFooter>
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <LinearProgress variant='indeterminate' />
                        </TableCell>
                      </TableRow>
                    )}
                    {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Pagination
                            page={pagina}
                            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                            onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString() }, { replace: true })}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableFooter>
                </Table>
              </TableContainer>
              <DialogSolicitacaoOS
                id={detalheterceiro}
                value={''}  
                open={open} 
                onClose={handleClose}
                idFazenda={id}
              /> 
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>   
              <FerramentasDaListagem
                textoTitulo='Peças Utilizadas'
                mostrarTextoTitulo={true}
                textoBotaoNovo='Adicionar'
                aoClicarEmNovo={() => {setIditens('novo');  setOpenSaida(true);}}
              />

              <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                <Table size="small">
                  <TableHead  sx={{ backgroundColor: '#5E8A71' }}>
                    <TableRow >
                      <TableCell style={{ color: '#fff' }} align='right'>Qtd</TableCell>
                      <TableCell style={{ color: '#fff' }} align='left'>UN</TableCell>
                      <TableCell style={{ color: '#fff' }} align='left'>Descrição</TableCell>
                      <TableCell style={{ color: '#fff' }} align='left'>Referência</TableCell>
                      <TableCell style={{ maxWidth:'2px', maxHeight: '10px',  color: '#fff' }} align='center'>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow key={row.iditens} 
                        hover
                        sx={{ cursor: 'pointer' }}
                      >
                        {/*  */}
                        <TableCell align='right' >{row.qtdsaida.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits:2})}</TableCell> 
                        <TableCell align='left'>{row.un}</TableCell>
                        <TableCell align='left' >{row.descricao}</TableCell>
                        <TableCell align='left'>{row.referencia}</TableCell>
                        <TableCell>
                          <IconButton size="small" onClick={() => {setIditens(row.iditens); handleBuscarDados(row.iditens);  setOpenSaida(true);}}>
                            <Icon >edit</Icon>
                          </IconButton>
                          <IconButton disabled={disabled ? isLoading : true}  size="small" onClick={() => {handleDeleteItem(row.iditens);}}>
                            <Icon>delete</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <LinearProgress variant='indeterminate' />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableFooter>
                </Table>
              </TableContainer>
              <DialogSaidaOS 
                id={iditens} 
                value={''} 
                dados={dadositens}
                open={openSaida} 
                onDados={handleItens} 
                onClose={handleCloseSaida} 
                status={disabled}
                idlocalizacao={idlocalizacao}
              /> 
            </Grid>
          </Grid>
        </Grid>
      </Box>    
    </LayoutBaseDePagina>
  );
};