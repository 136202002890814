
import { blue } from '@mui/material/colors';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
 
interface IAbastecimentos {
  dataoperacao: Date;
  numero: string;
  horimetronicial: number;
  horimetro: number;
  horastrabalhadas: number;
  quantidade: number;
  litros: number;
  operador: string;
  cultivo: string;
  pivot: string;
  atividade: string;
}

async function reabastecimento(dados: IAbastecimentos[], texto: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const tlitros = dados.reduce((acc, item) => acc + item.quantidade, 0);

  const rows = dados.map((row) => {
    return [
      {text: moment(row.dataoperacao).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8},  
      {text: row.numero, alignment: 'center',  fontSize: 7},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.horimetronicial), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.quantidade), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.horastrabalhadas), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.quantidade), alignment: 'center',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.litros), alignment: 'center',  fontSize: 8},
      {text: row.cultivo, alignment: 'left',  fontSize: 8},
      {text: row.pivot, alignment: 'left',  fontSize: 8},
      {text: row.operador, alignment: 'left',  fontSize: 8},
      {text: row.atividade, alignment: 'left',  fontSize: 8},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [20, 10, 20, 10],
    pageOrientation: 'landscape',
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [48, 800],
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
               
                border: [false, false, false, false],
                table: {
                  widths: [700],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Abastecimento', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      {text: ' '+texto, border: [false, false, false, false]},
      {
        table:{
          widths: [ 43, 40, 40, 40, 40, 30, 40, 100, 60, 150, 100],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},  
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [
              {text: 'Data', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Patrimonio', alignment: 'left', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Inicial', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Final', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Ho/KM', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Litros', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Consumo', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Cultivo', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
              {text: 'Pivot', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
              {text: 'Operador', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
              {text: 'Atividade', alignment: 'left', fillColor: '#dddddd', fontSize: 8},
            ],
            ...rows,
          ]
          
        },
      },
      {text: ' ', border: [false, false, false, false]},
      {text: 'Total de Litros no Periodo: '+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(tlitros), blue, alignment: 'left',  fontSize: 12},
      
    ] };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default reabastecimento;