
import {  Alert, Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid,  InputLabel,  LinearProgress,  MenuItem,  Paper, Select, Snackbar, SnackbarCloseReason, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { AutoCompleteCultivo01} from '../../../shared/forms';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { RelatorioEstoqueService } from '../../../shared/services/api/estoquefazenda/Relatorios/RelatoriosService';
import reabastecimento from './ReAbastecimento';
import { PatrimoniosService } from '../../../shared/services/api/cadastros/PatrimoniosService';
import { FazendaService } from '../../../shared/services/api/cadastros/FazendaService';



interface JwtPayload {
  faz: string;
  loc: string;
}


export const Relatorioabastecimento: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [uidfazenda, setUidFazenda] = useState('');    
  const [fazenda, setFazenda] = useState('');        
  const [uidcultivo, setUidCultivo] = useState('');   
  const [cultivo, setCultivo] = useState('');  
  const [uidpatrimonio, setUidpatrimonio] = useState('');    
  const [tipo, setTipo] = useState('fazenda');      
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs());      
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs()); 
  const [texto, setTexto] = useState('');   
  const [numeroPatrimonio, setNumeroPatrimonio] = useState('');
  const [descricaoPatrimonio, setDescricaoPatrimonio] = useState('');  
  const [openmsg, setOpenmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error'  | 'info'  | 'success'>('warning');  
          
  const handleCloseMsg = (
    event?: React.SyntheticEvent | Event, 
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenmsg(false);
  };    
 
 
  const handleVisualizar = () => {            
    setIsLoading(true);
    if(tipo === 'fazenda'){
      setTexto(`Fazenda: ${fazenda} Período: ${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`);
    }else if(tipo === 'cultivo'){
      setTexto(`Cultivo :${cultivo} Período :${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`);
    }else{
      setTexto(`Numero: ${numeroPatrimonio} Patrimonio: ${descricaoPatrimonio}  Período :${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`); 
    }
    RelatorioEstoqueService
      .relatorioAbastecimento(uidfazenda, uidcultivo, uidpatrimonio, tipo, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'))
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          setTms('error');
          setMsg(result.message);
          setOpenmsg(true);
        } else {     
          if(result.length > 0){   
            reabastecimento(result, texto);         
          }else{
            setTms('warning');
            setMsg('Nenhum registro encontrado');
            setOpenmsg(true);
          }                  
        }
      });  
  };

   useEffect(() => {
      const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
      if (accessToken) {
        const decoded = jwtDecode<JwtPayload>(accessToken);
        setUidFazenda(decoded.faz);
        FazendaService.getAlllist(decoded.faz)
          .then((result) => {
            if (result instanceof Error) {
            } else {
              result.data.map(fazenda => ({ label: fazenda.descricao })).forEach(opcao => {
                setFazenda(opcao.label);
              });
            }
          });
      }
    }, []);
  const handleCultivo = (uuidcultivo: string | undefined) => {
    if (uuidcultivo != null) {
      setUidCultivo(uuidcultivo);        
    }else{
      setUidCultivo('');               
    }          
  };

  const handleCultivoDescricao = (descricao: string | undefined) => {
    if (descricao != null) {
      setCultivo(descricao);        
    }else{
      setCultivo('');               
    }          
  };

  const handlebusca = (numeroPatrimonios: string) => {
    setIsLoading(true);
    if (numeroPatrimonios) {
   
      PatrimoniosService.getAllListNumero(numeroPatrimonios)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            setTms('error');
            setMsg(result.message);
            setOpenmsg(true);
          } else {
            setDescricaoPatrimonio(result.descricao);
            setUidpatrimonio(result.idpatrimonio);
          }
        });
    }
  };

  return (
    <Box  alignItems="center" width='100%' alignContent='center' height="50%"  display="flex" flexDirection="column"  >
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openmsg} autoHideDuration={900} onClose={handleCloseMsg}>
        <Alert
          onClose={handleCloseMsg}
          severity={tms}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Box gap={1}  display="flex" alignItems="center" alignSelf='center' width='80%' height="80%" margin={1}  alignContent='center'></Box>
      <Grid container spacing={1} item direction="row" xs={12} sm={12} md={12} lg={7} xl={5.5}>
        <Box   width='auto'  component={Paper} >       
          <DialogTitle>Relatório de Abastecimento</DialogTitle>
          <DialogContent  dividers>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid> 
            )}    
            <Grid container item direction="row">
              <Grid container spacing={1} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={10} sm={10} md={10} lg={12} xl={12}>
                <TextField
                    fullWidth
                    size='small'
                    value={fazenda}
                    disabled={true}
                    label='Fazenda'
                  />           
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                  <TextField
                    fullWidth  
                    size='small'
                    value={numeroPatrimonio}
                    label='Nº Patrimônio'
                    onChange={e => setNumeroPatrimonio(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && numeroPatrimonio.trim() !== '') {
                        handlebusca(numeroPatrimonio);
                      }
                    }}
                  />              
                </Grid> 
                <Grid item xs={8} sm={8} md={4} lg={10} xl={10}>     
                  <TextField
                    fullWidth
                    size='small'
                    value={descricaoPatrimonio}
                    disabled={true}
                    label='Patrimônio'
                  />   
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>   
                  <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">{'Tipo'}</InputLabel>
                    <Select
                      fullWidth
                      size='small'
                      value={tipo}
                      label='tipo'
                      onChange={(event) => setTipo(event.target.value)}
                    >
                      <MenuItem value={'fazenda'}>Fazenda</MenuItem>
                      <MenuItem value={'cultivo'}>Cultivo</MenuItem>
                      <MenuItem value={'patrimonio'}>Patrimonio</MenuItem>
                    </Select>  
                  </FormControl>         
                </Grid> 
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AutoCompleteCultivo01
                    uuidCultivo={handleCultivo} 
                    descricao={handleCultivoDescricao} 
                  />             
                </Grid> 
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format='DD/MM/YYYY'  
                      value={dataInicial}
                      onChange={(newValue) => setDataInicial(newValue)} 
                      slotProps={{
                        textField: {    
                          size:'small',
                        }
                      }}
                    />
                  </LocalizationProvider>     
                </Grid>  
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format='DD/MM/YYYY'
                      value={dataFinal}
                      onChange={(newValue) => setDataFinal(newValue)}  
                      slotProps={{
                        textField: {    
                          size:'small',
                        }
                      }}
                    />
                  </LocalizationProvider>   
                </Grid>           
              </Grid>     
            </Grid>
          </DialogContent>  
          <DialogActions>
            <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={handleVisualizar}>Visualizar</Button>
          </DialogActions>
        </Box>
      </Grid>
    </Box>
  );
};