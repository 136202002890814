import { useEffect, useState } from 'react';
import { Alert, Avatar, Box, Grid, LinearProgress, Paper, Snackbar, SnackbarCloseReason } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VForm, useVForm, IVFormErrors, AutoCompleteFuncionario, BasicSelect, VTextFieldData,} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import { AlimentacaoService } from '../../../shared/services/api/fazenda/alimentacao/AlimentacaoService';

interface IFormData {
  idfun: string;
  tipo: string;
  datalan: Date;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfun: yup.string().required(),
  tipo: yup.string().required(),
  datalan: yup.date().required('O campo é obrigatório')
  .test('valid-date', 'Selecione uma data válida', function (value) {
    const dataAtual = new Date(); // Data de hoje
    const quinzeDiasAtras = new Date();
    quinzeDiasAtras.setDate(dataAtual.getDate() - 30); // Calcula a data de 15 dias atrás

    if (!value) return false; // Certifica-se de que um valor foi fornecido
    if (value < quinzeDiasAtras || value > dataAtual) return false; // Valida se está dentro do intervalo

    return true; // Data válida
  }),
});

export const Alimentacao: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [urlimg, setUrlimg] = useState('');
  const [openmsg, setOpenmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error' | 'info' | 'success'>('warning');

  const handleCloseMsg = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenmsg(false);
    if (tms === 'success') {
      novoLancamento();
    }
  };
  
  useEffect(() => {
    if (id === 'novo') {
      formRef.current?.setData({
        idfun: '',
        tipo: '',
        datalan: dataFormatada,
      });
      setUrlimg('');
      
    }
  }, [id]); 

  const dataFormatada = () => {
    const dataAtual = new Date(); // Data de hoje
    return dataAtual.toISOString().split('T')[0]; // Retorna no formato YYYY-MM-DD
    
  };
  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(Environment.URL_IMGFUNCIONARIO+urlimg);
    }else{
      setUrlimg('/logo.png');
    }
  };

  const novoLancamento = () =>{
    formRef.current?.setData({
      idfun: '',
      tipo: '',
      datalan: dataFormatada,
    });
    setUrlimg('');
  }

  const handleSave = (dados: IFormData) => {
    
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          AlimentacaoService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                setTms('error');
                setMsg(result.message);
                setOpenmsg(true); 
              }else{
                setTms('success');
                setMsg('Lançamento Realizado com sucesso!');
                setOpenmsg(true);
              }
            });
        } 
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Alimentação' : ''}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoSalvar
          mostrarBotaoVoltar={false}
          mostrarBotaoSalvarEFechar={false}
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={false}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvar={save} 
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave} placeholder=""onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openmsg} autoHideDuration={900} onClose={handleCloseMsg}>
          <Alert
            onClose={handleCloseMsg}
            severity={tms}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5} >
                <Box width="100%" marginRight={5} >
                  <Avatar
                    variant='rounded'
                    alt='F'
                    src={urlimg}
                    sx={{ width: '100%', height: 200 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={6} xl={6}>
                <Grid container item direction="row" spacing={2}> 
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteFuncionario 
                  isExternalLoading={id === 'novo' ? isLoading : true}
                  urlfoto={handlefoto}
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>          
                <BasicSelect
                  data={[
                    { nameBD: 'C', descricao: 'Café' },
                    { nameBD: 'A', descricao: 'Almoço' },
                    { nameBD: 'J', descricao: 'Janta' },
                  ]}
                  label='Tipo'
                  name='tipo'
                  disabled={isLoading}
                />
              </Grid>
               <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>          
                  <VTextFieldData
                    fullWidth
                    name='datalan'
                    disabled={false}
                    label='Data'
                     />
                 </Grid>
              </Grid>
          </Grid>
          </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};