
import dayjs, { Dayjs } from 'dayjs';
import { Environment } from '../../../../environment';
import { Apiestoque } from '../../estoque/axiosestoque';
import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosError } from 'axios';

type NullableDate = Date | null | undefined;

export interface IListagemTransferencias { //
  idtransferencia: string;
  datalan: Date;
  portador: string;
  origem: string;
  destino: string;
  status: string;
}

export interface IitensTransferenciaDialog{
  iditens: string;
  idprod: string;
  qtdsaida: number; 
  validade?: string | null;
  qtdentrada: number;
  preco: number;
  un: string;
  conversor: number;
  total: number;

  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  reqvencimento: boolean; 
}

interface IgetTransferencia {
  iddestino: string;
  idorigem: string;
  datalan: Date;
  dataenvio?: NullableDate;
  datarecebimento?: NullableDate;
  portador: string;
  status: number;
  itens: IitensTransferenciaDialog[];
}

interface IDetalheTransferencia {
  iddestino: string;
  datalan: Date;
  dataenvio?: NullableDate;
  datarecebimento?: NullableDate;
  portador: string;
  status: number;
}

type TotalCount = {
  data: IListagemTransferencias[];
  totalCount: number;
}

interface ErrorResponse {
  error: {
    default: string;
  };
}

interface EstoqueQuantidade {
  idprod: string; 
  vencimento: boolean; 
  validade: string | Dayjs | null | undefined;
}

interface Quantidade{
  quantidade: number;
}

const getAll = async (page = 1, dataInicial = '', dataFinal='', tipo=''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/transferenciaestoque?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&tipo=${tipo}&inicial=${dataInicial}&final=${dataFinal}&tiposistem=fazenda`;
    const { data, headers } = await Apiestoque.get(urlRelativa);  
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

const getById = async (id: string): Promise<IgetTransferencia | Error> => {
  try {
    const { data } = await Apiestoque.get(`/transferenciaestoque/${id}`);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

const create = async (dados: IDetalheTransferencia, dadositens: IitensTransferenciaDialog[]): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const dadosExtraidos = dadositens.map(({ qtdestoque, referencia, descricao, reqvencimento, filtro, ...restoDoObjeto }) => ({ idoperacao: guuid, ...restoDoObjeto }));
    const dadosEntrada = {idtransferencia: guuid, dataenvio: dayjs(), ...dados};
    const { data } = await Apiestoque.post<IDetalheTransferencia>('/transferenciaestoquefazenda',  [dadosEntrada, dadosExtraidos]);
    
    if (Object.keys(data).length === 0) {
      return guuid;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

const estoqueQuantidade = async (dados: EstoqueQuantidade): Promise<Quantidade | Error> => {
  
  try {
    
    const { data } = await Apiestoque.post('/estoquequantidadefaz', dados);  

    if (data) {
      return (data);
    }

    return new Error('Erro ao pesquisar quantidade do ítem selecionado.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

export const TransferenciaService = {
  getAll,
  create,
  getById,
  estoqueQuantidade,
};