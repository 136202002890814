import { useState } from 'react';
import { Alert, Box, Button, Card, CardActions, CardContent, CircularProgress, Snackbar, SnackbarCloseReason, TextField, Typography } from '@mui/material';
import * as yup from 'yup';

import { useAuthContext } from '../../contexts';


const loginSchema = yup.object().shape({
  usuario: yup.string().required(),
  senha: yup.string().required().min(5),
});

interface ILoginProps {
  children: React.ReactNode;
}
export const Login: React.FC<ILoginProps> = ({ children }) => {
  const { isAuthenticated, login } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [senhaError, setSenhaError] = useState('');
  const [usuarioError, setUsuarioError] = useState('');
  const [senha, setSenha] = useState('');
  const [usuario, setUsuario] = useState('');
  const [openmsg, setOpenmsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [tms, setTms] = useState<'warning' | 'error'  | 'info'  | 'success'>('warning');  


  const handleCloseMsg = (
    event?: React.SyntheticEvent | Event, 
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenmsg(false);
  };
 
  const handleSubmit = () => { 
    setIsLoading(true);

    loginSchema
      .validate({ usuario, senha }, { abortEarly: false })
      .then(dadosValidados => {
        login(dadosValidados.usuario, dadosValidados.senha)
          .then(() => {
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            setTms('error');
            setMsg(error?.message || 'Usuário ou senha incorretos');
            setOpenmsg(true);
          });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'usuario') {
            setUsuarioError(error.message);
          } else if (error.path === 'senha') {
            setSenhaError(error.message);
          }
        });
      });
  };


  if (isAuthenticated) return (
    <>{children}</>
  );

  return (
    <Box  width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openmsg}  autoHideDuration={900} onClose={handleCloseMsg}>
        <Alert
          onClose={handleCloseMsg}
          severity={tms}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {msg}
        </Alert>
      </Snackbar>;
      <Card  >
        <CardContent >
          <Box display='flex' flexDirection='column' gap={2} width={350}>
            <Typography variant='h6' align='center'>Identifique-se</Typography>
            <TextField
              fullWidth
              type='login' 
              label='Usuário'
              value={usuario}
              disabled={isLoading}    
              error={!!usuarioError}
              helperText={usuarioError}
              onKeyDown={() => setUsuarioError('')}
              onChange={e => setUsuario(e.target.value)}
            />

            <TextField
              fullWidth
              label='Senha'
              type='password'
              value={senha}
              disabled={isLoading}
              error={!!senhaError}
              helperText={senhaError}
              onKeyDown={() => setSenhaError('')}
              onChange={e => setSenha(e.target.value)}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Box width='100%' display='flex' justifyContent='center'>

            <Button
              variant='contained'
              disabled={isLoading}
              onClick={handleSubmit}
              endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
            >
              Entrar
            </Button>
          
          </Box>
        </CardActions>
      </Card>

    </Box>
  );
};