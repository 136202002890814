import { Avatar, Divider, Drawer,Collapse, Icon, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { Box } from '@mui/system';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useAppThemeContext, useAuthContext, useDrawerContext } from '../../contexts';
import { Environment } from '../../environment';
import { DialogPefilFoto } from './DialogPefilFoto';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { DialogPefil } from './DialogPerfil';

interface IListItemLinkProps {
  to: string;
  icon: string;
  label: string;
  subItems?: Array<{ label: string; icon: string; to: string }>;
  openItemIndex: string | null;
  setOpenItemIndex: (index: string | null) => void; // Função para atualizar o estado
  onClick: (() => void) | undefined;
}
const ListItemLink: React.FC<IListItemLinkProps> = ({ to, icon, label, subItems, openItemIndex, setOpenItemIndex, onClick }) => {
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });
  
  const handleClick = () => {
    if (subItems) {
      if (openItemIndex === to) {
        setOpenItemIndex(null); // Fecha o item se ele já estiver aberto
      } else {
        setOpenItemIndex(to); // Abre o item clicado e fecha os outros
      }
    } else {
      navigate(to);
      onClick?.();
    }
  };
  return(
    <>
    <ListItemButton selected={!!match} onClick={handleClick}>
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={label} />
      {subItems ? (openItemIndex === to ? <ExpandLess /> : <ExpandMore />) : null}
    </ListItemButton>

    {subItems && (
      <Collapse in={openItemIndex === to} timeout="auto" unmountOnExit>
        <Box sx={{ pl: 2 }}>
          {subItems.map((subItem) => {
            const subResolvedPath = useResolvedPath(subItem.to);
            const subMatch = useMatch({ path: subResolvedPath.pathname, end: false });

            return (
              <ListItemButton
                key={subItem.to}
                selected={!!subMatch} // Marcar o subitem se ele corresponder à rota
                onClick={() => {
                  navigate(subItem.to);
                  onClick?.();
                }}
              >
                <ListItemIcon>
                  <Icon>{subItem.icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={subItem.label} />
              </ListItemButton>
            );
          })}
        </Box>
      </Collapse>
    )}
  </>
);
};
interface IMenuLateralProps {
  children: React.ReactNode
}

export const MenuLateral: React.FC<IMenuLateralProps> = ({ children }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [openItemIndex, setOpenItemIndex] = useState<string | null>(null);
  const { isDrawerOpen, drawerOptions, drawerOptionsr, toggleDrawerOpen, drawerItemOptions } = useDrawerContext();
  const { toggleTheme } = useAppThemeContext();
  const { logout, logoutsistema } = useAuthContext();
  const [openFoto, setOpenFoto] = useState(false);
  const [openPerfil, setOpenPerfil] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fotoperfil, setFotoperfil] = useState('');
  const [iduser, setIduser] = useState('');
  const open = Boolean(anchorEl);
  
  interface JwtPayload{
    uid: string;
    img: string;
  }

  const handleCloseFoto = () => {
    setOpenFoto(false);
  };

  const handleClosePerfil = () => {
    setOpenPerfil(false);
  };

  const handleFotoPerfil = () => {
    setOpenFoto(true);
    setAnchorEl(null);
  };

  const handlePerfil = () => {
    setOpenPerfil(true);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
    if (accessToken) {
      const decoded = jwtDecode<JwtPayload>(accessToken);
      setFotoperfil(Environment.URL_IMGUSUARIOS+decoded.img);
      setIduser(decoded.uid);
    }
  }, []);

  return (
    <>
      <Drawer open={isDrawerOpen} variant={smDown ? 'temporary' : 'permanent'} onClose={toggleDrawerOpen}>
        <Box width={theme.spacing(28)} height="100%" display="flex" flexDirection="column">
          <Box width="100%" height={theme.spacing(15)} display="flex" alignItems="center" justifyContent="center">
            <div
              id="avatar-button"
              aria-controls={open ? 'avatar-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{ cursor: 'pointer' }}
            >
              <Avatar 
                sx={{ height: theme.spacing(12), width: theme.spacing(12) }}
                src={fotoperfil}
              />
            </div>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose1}
              MenuListProps={{
                'aria-labelledby': 'avatar-button',
              }}
            >
              <MenuItem onClick={handleFotoPerfil}>Foto do Perfil</MenuItem>
              <MenuItem onClick={handlePerfil}>Perfil</MenuItem>
            </Menu> 
          </Box>
          <Divider />

          <Box flex={1}>
  <List component="nav">
    {drawerOptions.map(drawerOption => (
      <ListItemLink 
        key={drawerOption.path}
        icon={drawerOption.icon}
        to={drawerOption.path}
        label={drawerOption.label}
        subItems={drawerItemOptions
          .filter((item) => item.parent === drawerOption.path) 
          .map((item) => ({
            label: item.label,
            icon: item.icon,
            to: item.path,
          }))}
        openItemIndex={openItemIndex}  // Passando o estado
        setOpenItemIndex={setOpenItemIndex} // Passando a função para atualizar o estado
        onClick={smDown ? toggleDrawerOpen : undefined}
      />
    ))}
  </List>
</Box>
            <List component="nav">
              <ListItemButton onClick={toggleTheme}>
                <ListItemIcon>
                  <Icon>dark_mode</Icon>
                </ListItemIcon>
                <ListItemText primary="Alternar tema" />
              </ListItemButton>
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <Icon>logout</Icon>
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItemButton>
            </List>
          </Box>
      </Drawer>
      <DialogPefilFoto
        id={iduser}
        img={fotoperfil}
        value={''}  
        open={openFoto} 
        onClose={handleCloseFoto}
      /> 
      <DialogPefil
        id={iduser}
        value={''}  
        open={openPerfil} 
        onClose={handleClosePerfil}
      /> 
      <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
        {children}
      </Box>
    </>
  );
};