import { Box, Button, Icon, Paper, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

interface IFerramentasDaListagemProps {
  textoDaBusca?: string;
  mostrarInputBusca?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  aoClicarEmNovo?: () => void;
}
export const FerramentasDaListagemRdiario: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  aoMudarTextoDeBusca,
  mostrarInputBusca = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
}) => {
  const theme = useTheme();
  const partesDaData = textoDaBusca.split('/');
  const dataFormatada = partesDaData[2] + '-' + partesDaData[1] + '-' + partesDaData[0];

  return (
    <Box
      marginX={2}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
    >
      {mostrarInputBusca && (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label="Data" 
          format='DD/MM/YYYY'
          defaultValue={dayjs()}
          value={dayjs(dataFormatada)}
          onChange={(date: Dayjs | null) => {
            const novoTexto = date ? date.format('DD/MM/YYYY') : '';
            aoMudarTextoDeBusca?.(novoTexto);
          }}
          slotProps={{
            textField: {    
              size:'small',
            }
          }}
        />
      </LocalizationProvider>
      )} 

      <Box flex={1} display="flex" justifyContent="end">
        {mostrarBotaoNovo && (
          <Button
            color='primary'
            disableElevation
            variant='contained'
            onClick={aoClicarEmNovo}
            endIcon={<Icon>add</Icon>}
          >{textoBotaoNovo}</Button>
        )}
      </Box>
    </Box>
  );
};