import axios, { AxiosError } from "axios";
import { Apiestoque } from "../../estoque/axiosestoque";

export interface ILocalizacao {
  idprod: string;
  descricao: string;
  referencia: string;
  qtdestoque: number;
}

export interface IVencimentos {
  idprod: string;
  descricao: string;
  referencia: string;
  validade: Date;
  dias: number;
  qtdestoque: number;
}

export interface IOperacao{
  idoperacoes: string;
  idfazenda: string;
  idcultivo: string;
  datainicial: Date;
  datafinal: Date;
  detalhes: boolean;
}

interface Item {
  iditens: string;
  descricao: string;
  validade: Date;
  referencia: string;
  localizacao: string;
  qtdsaida: number;
  preco: number;  
  total: number;
}

interface Saida {
  idsaida: string;
  datalan: string;
  cultivo: string;
  fazenda: string;
  parceiro: string;
  total: number;
}
export interface IAbastecimento {
  dataoperacao: Date;
  numero: string;
  horimetronicial: number;
  horimetro: number;
  horastrabalhadas: number;
  quantidade: number;
  litros: number;
  operador: string;
  cultivo: string;
  pivot: string;
  atividade: string;
}

interface Entry {
  saida: Saida;
  itens: Item[];
}

interface ErrorResponse {
  error: {
    default: string;
  };
}

const Localizacao = async (localizacao = '', tipo = '', grupo = '', subgrupo = ''): Promise<ILocalizacao[] | Error> => {
  try {
    const urlRelativa = `/rlocalizacao?tipo=${tipo}&localizacao=${localizacao}&grupo=${grupo}&subgrupo=${subgrupo}`;
    const { data } = await Apiestoque.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

const Vencimentos = async (localizacao = '', tipo = '', dias = ''): Promise<IVencimentos[] | Error> => {
  try {
    const urlRelativa = `/restoquevencimentos?tipo=${tipo}&localizacao=${localizacao}&dias=${dias}`;
    const { data } = await Apiestoque.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};
const relatorioAbastecimento = async (fazenda = '', cultivo = '', patrimonio = '', tipo = '', datainicial: string, datafinal: string): Promise<IAbastecimento[] | Error> => {
  try {
    const urlRelativa = `/rabastecimento?idfazenda=${fazenda}&idcultivo=${cultivo}&idpatrimonio=${patrimonio}&tipo=${tipo}&datainicial=${datainicial}&datafinal=${datafinal}`;
    const { data } = await Apiestoque.get(urlRelativa);
    if (data) {   
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

const Operacoes = async (dados: IOperacao): Promise<Entry[] | Error> => {
  try {
    const { data } = await Apiestoque.post<Entry[]>('/restoqueoperacoes', dados);
    if (data) {
      return data;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};
 
export const RelatorioEstoqueService = {
  Localizacao,
  Vencimentos,
  relatorioAbastecimento,
  Operacoes,
}; 