import axios, { AxiosError } from 'axios';
import { Apicadastro } from '../../axioscadastro';

export interface IDetalhePatrimonio {
  idpatrimonio: string;
  descricao: string;
  modelo: string;
  marca: string;
  horimetro: number;
  veiculos: boolean;
  litros: number;
  tipomedicao: string;
  fotos: [];
}

interface ErrorResponse {
  error: {
    default: string;
  };
}

const getAll = async (numero = ''): Promise<IDetalhePatrimonio | Error> => {
  try {
    const urlRelativa = `/patrimonionumero?numero=${numero}`;

    const { data } = await Apicadastro.get(urlRelativa);

    if (data) {
      return data;

    } else {
      throw new Error('Nenhum dado retornado. Código incorreto.');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {  
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

export const PatrimonioService = {
  getAll,
};